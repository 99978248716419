import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const extension = value.split('.').pop();
    const video = 'video_label';
    const mappings = {
      jpg: 'image',
      png: 'image',
      gif: 'image',
      bmp: 'image',
      mp4: video,
      wmv: video,
      webm: video,
      avi: video,
      flv: video
    };
    let icon = mappings[extension];
    if (!icon) { icon = 'text_format'; }
    return icon;
  }
}
