import { Component, Inject, OnInit } from '@angular/core';
import moment from 'moment';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicFormModel } from 'src/app/dynamic-forms/dynamic-form.model';
import { DynamicFormFieldAnswerModel } from 'src/app/dynamic-forms/dynamic-form-field-answer.model';
import { DynamicFormFieldOptionAnswerModel } from 'src/app/dynamic-forms/dynamic-form-field-option-answer.model';
import {DynamicFormFieldModel} from "../dynamic-form-field.model";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {
  DynamicFormsAssignedUsersAuditLogsComponent
} from "../dynamic-forms-assigned-users-audit-logs/dynamic-forms-assigned-users-audit-logs.component";

@Component({
  selector: 'app-dynamic-forms-assigned-users-form-view',
  templateUrl: './dynamic-forms-assigned-users-form-view.component.html',
  styleUrls: ['./dynamic-forms-assigned-users-form-view.component.scss']
})
export class DynamicFormsAssignedUsersFormViewComponent implements OnInit {

  form: DynamicFormModel = {
    is_template: true,
    is_active: false,
    is_recurring: false,
    schedule: 'day-of-month',
    schedule_minute: 0,
    schedule_hour: 8,
    schedule_days: [],
    schedule_months: [],
    schedule_weeks: [],
    expires_at: 0,
    form_type: 'form',
    fields: [],
    user: {}
  } as DynamicFormModel;

  currentDate: Date = moment().toDate();
  currentFormStatus: String = '';

  form_id: number;
  user_id: number;

  constructor(
    public utils: UtilsService,
    private api: ApiRequestService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<DynamicFormsAssignedUsersFormViewComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { form_id: number, user_id: number }
  ) { }

  ngOnInit() {
    // Store the form and user id in this class.
    this.form_id = this.dialogData.form_id;
    this.user_id = this.dialogData.user_id;
    // Check if the form id and user id is present. Close the dialog if it is not.
    if ( !this.form_id || !this.user_id ) {
      this.utils.showToast('The form id and user id is not valid.');
      this.dialogRef.close();
      return;
    }
    // Make an API request to get the form data.
    this.api.makeRequest('get', `v2/dynamic-forms/assigned-users-form-answers/${this.form_id}/${this.user_id}`)
      .then((response: DynamicFormModel) => {
        // Apply the response as the form object.
        this.storeAndPrepareFormData(response);
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Stores the form data and prepares the form fields for viewing.
   * @param form
   * @private
   */
  private storeAndPrepareFormData(form: DynamicFormModel) {
    // Apply the response as the form object.
    this.form = form;

    this.currentFormStatus = this.form.pivot.dynamic_form_status;

    this.form.fields.forEach((field) => {
      // Initialise new field answer models if none is present.
      if ( typeof field.field_answer == 'undefined' || !field.field_answer ) {
        field.field_answer = {
          dynamic_form_field_id: field.id,
          answer: ''
        } as DynamicFormFieldAnswerModel;
      }

      // Create field option answers if none is present.
      field.field_options.forEach((fieldOption) => {
        if ( typeof fieldOption.option_answer == 'undefined' || !fieldOption.option_answer ) {
          fieldOption.option_answer = {
            dynamic_form_field_option_id: fieldOption.id,
            answer: false
          } as DynamicFormFieldOptionAnswerModel;
        }
      });
    });
  }


  /**
   * Make a request to the API to update the form status and notes.
   */
  onUpdateFormNotesAndStatus() {
    if ( !this.form_id || !this.user_id ) {
      this.utils.showModal('Update Error', 'Failed to update the form notes and status. Please close this dialog, re-open it and try again.');
      return;
    }

    // Make an API request to update the form status.
    this.api.makeRequest('put', `v2/dynamic-forms/update-assigned-users-form/${this.form_id}/${this.user_id}`, this.form.pivot)
      .then((response: DynamicFormModel) => {
        // Apply the response as the form object.
        this.storeAndPrepareFormData(response);
        // Alert the user of successful updates
        this.utils.showToast('The notes and status were successfully updated.');
      })
      .catch((errorResponse: any) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Evaluate field conditions to show/hide the field.
   * @returns
   * @param formField
   */
  evaluateConditions(formField?: DynamicFormFieldModel) {
    if ( formField.field_conditions && formField.field_conditions.length > 0 ) {
      let conditions_array: string[] = [];
      formField.field_conditions.forEach((condition, i) => {
        this.form.fields.forEach((targetField, j) => {
          if ( targetField.id == condition.dynamic_form_target_field_id && condition.condition_operator != "" ) {
            if ( ['checkbox', 'toggle'].indexOf(targetField.field_type) > -1 ) {
              targetField.field_options.forEach((fieldOption, k) => {
                if ( fieldOption.option_text == condition.condition_value ) {
                  conditions_array.push('this.form.fields['+j+'].field_options['+k+'].option_answer.answer ' + condition.condition_operator + ' ' + (condition.condition_checked ? 'true' : 'false'));
                }
              });
            } else {
              // If no field answer is provided, the field should remain hidden.
              if ( typeof targetField.field_answer != 'undefined' && targetField.field_answer != null && typeof targetField.field_answer.answer != 'undefined' && targetField.field_answer.answer != null ) {
                // Compare numeric field values without quotes. No value or null will be treated as 0.
                if ( targetField.field_type == 'input' && targetField.field_input_type == 'number' ) {
                  if ( targetField.field_answer.answer != '' ) {
                    conditions_array.push('this.form.fields['+j+'].field_answer.answer ' + condition.condition_operator + ' ' + Number(condition.condition_value));
                  } else {
                    // Hide if target field is unanswered.
                    conditions_array.push('false');
                  }
                } else {
                  conditions_array.push('this.form.fields['+j+'].field_answer.answer ' + condition.condition_operator + ' "' + (condition.condition_value ? condition.condition_value : '') + '"');
                }
              } else {
                // Hide if target field is unanswered.
                conditions_array.push('false');
              }
            }
          }
        });
      });
      // If any conditions are constructed, run the evaluation.
      if ( conditions_array.length > 0 ) {
        return eval(conditions_array.join(' ' + formField.field_logical_operator + ' '));
      }
    }
    return true;
  }

  /**
   * Send a request to the API to export the user's data to PDF.
   */
  onExportToPDF() {
    this.api.makeRequest('get', `v2/dynamic-forms/export-data/pdf/${this.form_id}`, {}, {
      user_ids: this.form.user.id
    })
    .then((response) => {
      this.utils.showToast(response.message);
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

  /**
   * Show the audit logs for the form and user.
   */
  onViewAuditLogEntries() {
    this.utils.showComponentDialog(DynamicFormsAssignedUsersAuditLogsComponent, {
        form_id: this.form_id,
        user_id: this.user_id
      }, {
      width: '90%'
    });
  }

  /**
   * Extracts phone details from the given event and updates the corresponding field in the form.
   * @param {any} event - The event containing the phone details.
   * @param {number} fieldIndex - The index of the field in the form.
   * @return {void}
   */
  extractPhoneDetails(event: any, fieldIndex: number): void {
    if ( event ) {
      // Extract the phone input field details.
      this.form.fields[fieldIndex].field_answer.answer = event.hasOwnProperty('number') && event.number !== null ? event.number : '';
      this.form.fields[fieldIndex].field_answer.meta['mobile_country_code'] = event.hasOwnProperty('countryCode') && event.countryCode !== null ? event.countryCode : '';
      this.form.fields[fieldIndex].field_answer.meta['mobile_dial_code'] = event.hasOwnProperty('dialCode') && event.dialCode !== null ? event.dialCode : '';
      this.form.fields[fieldIndex].field_answer.meta['mobile_e164'] = event.hasOwnProperty('e164Number') && event.e164Number !== null ? event.e164Number : '';
      this.form.fields[fieldIndex].field_answer.meta['mobile_error_state'] = event.hasOwnProperty('errorState') && event.errorState !== null ? event.errorState : true;
    }
  }

}
