<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="button-row">
    <button mat-raised-button routerLink="../.." class="margin-right">
      <mat-icon>arrow_back</mat-icon> Inspections & Audits
    </button>

    <button mat-raised-button class="margin-right"
      routerLink="/audits/{{ audit.id }}/edit">
      <mat-icon>edit</mat-icon>
      Edit
    </button>
  </div>
  <button mat-raised-button class="force-right" (click)="downloadPdf()">
    <mat-icon>save_alt</mat-icon>
    Download as PDF
  </button>
</nav>

<div class="app-padding">
  <h6>Inspection/Audit</h6>
  <p>{{ audit.name }}</p>

  <h6 *ngIf="audit.site">{{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}</h6>
  <p>{{ audit.site?.location }}</p>

  <h6 *ngIf="audit.positive_comments">Positive Comments</h6>
  <p>{{ audit.positive_comments }}</p>

  <h6>Created by</h6>
  <p>
    <a (click)="onUserPublicView(audit.created_by_hash)"
       matTooltip="View User Public Profile">
      {{ audit.created_by_name }}
    </a>
  </p>

  <h6>Created at</h6>
  <p class="extra-margin-bottom">
    {{ (audit.date_created * 1000) | timezoneConvertLong }}
  </p>

  <h6>Completion Status</h6>
  <p class="extra-margin-bottom">
    <span [matTooltip]="audit.items_count_completed + '/' + audit.items_count + ' items completed.'">
      {{ audit.completed }}% Completed.
    </span>
  </p>

  <div *ngIf="audit.contractors?.length > 0">
    <h4>Associated {{ utils.getLangTerm('contractors.plural', 'Contractors') }}</h4>
    <mat-card class="scroll-box extra-margin-bottom">
      <mat-card-content>
        <table mat-table [dataSource]="audit.contractors">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Company Name
            </th>
            <td mat-cell *matCellDef="let contractor"
              class="no-padding contractor-name">
              {{ contractor.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="industry">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Industry
            </th>
            <td mat-cell *matCellDef="let contractor"
              class="no-padding contractor-industry">
              {{ contractor.industry }}
            </td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Address
            </th>
            <td mat-cell *matCellDef="let contractor"
              class="no-padding contractor-address">
              {{ contractor.address }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="no-padding" width="100px">
<!--              Actions-->
            </th>

            <td mat-cell *matCellDef="let contractor">

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedContractorColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedContractorColumns">
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>

  <ng-template #NoContacts>
    <p>There are no contacts to display.</p>
  </ng-template>

  <div *ngIf="contactDataSource && contactDataSource.length > 0; else NoContacts">
    <mat-card class="scroll-box extra-margin-bottom">
      <mat-card-content>
        <h5>Sent to:</h5>
        <table mat-table [dataSource]="contactDataSource">
          <ng-container matColumnDef="contact_name">
            <th mat-header-cell *matHeaderCellDef class="no-padding" width="300px">
              Contact Name
            </th>
            <td mat-cell *matCellDef="let contact" class="no-padding">
              <p>{{ contact.contact_name }}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Email Address
            </th>
            <td mat-cell *matCellDef="let contact" class="p-3">
              <p>{{ contact.email }}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="user_status">
            <th mat-header-cell *matHeaderCellDef class="no-padding" width="200px">
              User Status
            </th>
            <td mat-cell *matCellDef="let contact" class="no-padding">
              <p>{{ contact.user_id ? 'SiteConnect User' : 'Non-SiteConnect User' }}</p>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedContactsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedContactsColumns"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>

  <ng-template #NoSections>
    <p>There are no sections and items to display.</p>
  </ng-template>

  <div *ngIf="sections.length > 0; else NoSections">
    <mat-card *ngFor="let section of sections" class="scroll-box extra-margin-bottom">

      <mat-card-content>
        <h5>Section: {{section}}</h5>

        <table mat-table [dataSource]="sectionsItems[section]">

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              #
            </th>
            <td mat-cell *matCellDef="let auditItem" class="p-3">
              {{ auditItem.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Question
            </th>
            <td mat-cell *matCellDef="let auditItem" class="p-3">
              <p>
                {{ auditItem.name }}
              </p>
              <mat-chip-list aria-label="List of Files" *ngIf="auditItem.files.length > 0">
                <mat-chip *ngFor="let file of auditItem.files" (click)="onOpenUrl(file.url)">{{ file.name }}</mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>

          <ng-container matColumnDef="section">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Section
            </th>
            <td mat-cell *matCellDef="let auditItem" class="no-padding">
              {{ auditItem.section }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="no-padding" width="150px">
              Status
            </th>
            <td mat-cell *matCellDef="let auditItem" class="no-padding">
              {{ auditItem.status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Description
            </th>
            <td mat-cell *matCellDef="let auditItem" class="no-padding">
              {{ auditItem.description | ellipsis: [30] }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date_created">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Date Created
            </th>
            <td mat-cell *matCellDef="let auditItem" class="no-padding">
              {{ (auditItem.date_created * 1000) | timezoneConvertMedium }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date_created_UTC">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Date Created (UTC)
            </th>
            <td mat-cell *matCellDef="let auditItem" class="no-padding">
              {{ (auditItem.date_created * 1000) | timezoneConvertUTCMedium }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date_modified">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Last Updated At
            </th>
            <td mat-cell *matCellDef="let auditItem" class="no-padding">
              {{ (auditItem.date_modified * 1000) | timezoneConvertMedium }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date_modified_UTC">
            <th mat-header-cell *matHeaderCellDef class="no-padding">
              Last Updated At (UTC)
            </th>
            <td mat-cell *matCellDef="let auditItem" class="no-padding">
              {{ (auditItem.date_modified * 1000) | timezoneConvertUTCMedium }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="no-padding" width="100px">
              Actions
            </th>

            <td mat-cell *matCellDef="let auditItem">
              <button type="button" mat-button (click)="view(auditItem)" matTooltip="View inspected/audited question." matTooltipPosition="above">
                View
              </button>
            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>


</div>
