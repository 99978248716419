import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { IncidentLtiTimeRecordModel } from 'src/app/models/incident-lti-time-record.model';
import { IncidentLtiModel } from 'src/app/models/incident-lti.model';
import { IncidentModel } from 'src/app/models/incident.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import moment from 'moment';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-incidents-ltis-time-records-edit',
  templateUrl: './incidents-ltis-time-records-edit.component.html',
  styleUrls: ['./incidents-ltis-time-records-edit.component.scss']
})
export class IncidentsLtisTimeRecordsEditComponent implements OnInit {

  incident: IncidentModel;

  incident_lti: IncidentLtiModel;

  incident_lti_time_record: IncidentLtiTimeRecordModel = new IncidentLtiTimeRecordModel;

  @ViewChild('timeRecordDateTimePicker') timeRecordDateTimePicker: NgxMatDatetimePicker<any>;
  timeRecordDateTime: moment.Moment;

  selectedTimezone: string;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    private utils: UtilsService,
    private dialogRef: MatDialogRef<IncidentsLtisTimeRecordsEditComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit() {
    // Store the incident
    if ( this.dialogData['incident'] ) {
      this.incident = this.dialogData['incident'];

      this.selectedTimezone = this.incident.site.timezone;
    }
    // Store the incident LTI
    if ( this.dialogData['incident_lti'] ) {
      this.incident_lti = this.dialogData['incident_lti'];
    }
    // Get the LTI time record id
    const incidentLtiTimeRecordId = Number(this.dialogData['incident_lti_time_record_id']);
    // Get the incident LTI time record
    if ( incidentLtiTimeRecordId ) {
      this.api.makeRequest('get', `v2/incidents/${this.incident.id}/ltis/${this.incident_lti.incident_lti_id}/time-records/${incidentLtiTimeRecordId}`)
      .then((response : IncidentLtiTimeRecordModel) => {
        this.incident_lti_time_record.apply(response);

        this.timeRecordDateTime = moment.unix(this.incident_lti_time_record.actual_lti_date);
      });
    }
  }

  getOutputTimezone($event) {
    this.selectedTimezone = $event;
  }

  onSave() {
    // date/time conversion
    const dateToSave = moment.tz(this.timeRecordDateTime.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedTimezone);
    this.incident_lti_time_record.actual_lti_date = dateToSave.unix();

    // Add form validation
    if ( this.incident_lti_time_record.incident_lti_time_record_id ) {
      // Update the LTI time record
      this.api.makeRequest('put', `v2/incidents/${this.incident.id}/ltis/${this.incident_lti.incident_lti_id}/time-records/${this.incident_lti_time_record.incident_lti_time_record_id}`, this.incident_lti_time_record)
      .then((response: IncidentLtiTimeRecordModel) => {
        this.incident_lti_time_record.apply(response);
      });
    } else {
      // Create the LTI time record
      this.api.makeRequest('post', `v2/incidents/${this.incident.id}/ltis/${this.incident_lti.incident_lti_id}/time-records`, this.incident_lti_time_record)
      .then((response: IncidentLtiTimeRecordModel) => {
        this.incident_lti_time_record.apply(response);
      });
    }
  }

  getMaxDaysLeftInSelectedMonth() {
    if ( !this.timeRecordDateTime ) {
      return 0;
    }
    const maxDaysLeftInSelectedMonth = Math.fround((this.timeRecordDateTime.daysInMonth() - this.timeRecordDateTime.date()) - ((this.incident_lti_time_record.actual_lti_hours / 8)));
    return maxDaysLeftInSelectedMonth > 0 ? maxDaysLeftInSelectedMonth : 0;
  }

  getMaxWorkingHoursLeftInSelectedMonth() {
    if ( !this.timeRecordDateTime ) {
      return 0;
    }
    const maxWorkingHoursInSelectedMonth = Math.round(((this.timeRecordDateTime.daysInMonth() - this.timeRecordDateTime.date()) * 8) - (this.incident_lti_time_record.actual_lti_days * 8));
    return maxWorkingHoursInSelectedMonth > 0 ? maxWorkingHoursInSelectedMonth : 0;
  }

  canSubmitForm(form: NgForm) {
    return form.valid && (
      this.incident_lti_time_record.actual_lti_days <= this.getMaxDaysLeftInSelectedMonth()
    ) && (
      this.incident_lti_time_record.actual_lti_hours <= this.getMaxWorkingHoursLeftInSelectedMonth()
    );
  }

}
