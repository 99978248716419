import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { ContractorModel } from '../models/contractor.model';

@Directive({
  selector: '[appColorInduction]'
})
export class ColorInductionDirective implements OnInit {
  @Input() appColorInduction: ContractorModel;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (
      // No inductions exist.
      this.appColorInduction.inductions_count === 0 ||
      // Every induction is complete.
      this.appColorInduction.inductions_completed ===
        this.appColorInduction.inductions_count
    ) {
      return this.renderer.setStyle(this.el.nativeElement, 'color', 'green');
    }

    else if (
      // Your inductions are in progress.
      this.appColorInduction.inductions_completed > 0 &&
      this.appColorInduction.inductions_count !==
        this.appColorInduction.inductions_completed
    ) {
      return this.renderer.setStyle(this.el.nativeElement, 'color', 'orange');
         }

    // You haven't started your induction.
    return this.renderer.setStyle(this.el.nativeElement, 'color', 'red');
  }
}
