<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span *ngIf="selectedSite.id; else noSelectedSite">{{ selectedSite.name }} <br /><small>{{selectedSite.location}}</small></span>
    <ng-template #noSelectedSite>You do not have a {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site').toLowerCase() }} selected yet.</ng-template>

    <span style="flex: 1 1 auto;"></span>

    <button mat-raised-button (click)="onChangeActiveSite()" color="secondary">
      <mat-icon>map</mat-icon> Change Active {{ utils.getLangTerm('parent-child-sites-combined.singular', 'Site') }}
    </button>
  </mat-toolbar-row>
</mat-toolbar>
