import { Component, OnInit, Input } from '@angular/core';
import { IncidentModel } from 'src/app/models/incident.model';
import { IncidentService } from '../incident.service';
import { UserModel } from 'src/app/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/utils.service';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';

@Component({
  selector: 'app-incidents-investigation',
  templateUrl: './incidents-investigation.component.html',
  styleUrls: ['./incidents-investigation.component.scss']
})
export class IncidentsInvestigationComponent implements OnInit {

  @Input() incident: IncidentModel;

  assignee: UserModel = {} as UserModel;
  reviewer: UserModel = {} as UserModel;

  private incidentId: number;

  constructor(
    private incidentService: IncidentService,
    private route: ActivatedRoute,
    private utils: UtilsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.incidentId = this.route.parent.snapshot.params['incident_id'];
    this.incidentService.findAssignee(this.incidentId).subscribe((response) => {
      this.assignee = response.data;
    });

    this.incidentService.getReviewer(this.incidentId).subscribe(
      response => this.reviewer = response.data
    );
  }

  onReviewedInvestigation() {
    this.incidentService.reviewedInvestigation(this.incident.id).subscribe(() => {
      this.utils.showToast('The investigation is now completed.');
      this.incident.status = 'Completed';
      this.router.navigate(['/incidents']);
    });
  }

  onSubmitInvestigation() {
    this.incidentService.submitInvestigation(this.incident.id).subscribe(() => {
      this.utils.showToast('The investigation is now submitted.');
      this.incident.status = 'Submitted';
      this.router.navigate(['/incidents']);
    });
  }

  onStartInvestigation() {
    this.incident.status = 'In Progress';
    this.incidentService.update(this.incident).subscribe(() => {
      this.utils.showToast('Started investigation');
    });
  }

  onSelectInvestigator() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.assignee.id],
        visitor_site_id: this.incident.site_id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.incidentService
        .setAssignee(this.incidentId, userId)
        .subscribe((response) => {
          this.utils.showToast('The investigator was changed successfully.');
          this.assignee = response.data;

          // Change the incident status to immediately reflect changes.
          // if ( this.incident.status === 'Reported' ) {
          //   this.incident.status = 'Assigned';
          // }

        });
      }
    );
  }

  onSelectReviewer() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.reviewer.id],
        visitor_site_id: this.incident.site_id
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.incidentService
        .updateReviewer(this.incidentId, userId)
        .subscribe((response) => {

          this.utils.showToast('The reviewer was successfully changed.');
          this.reviewer = response.data;

        });
      }
    );
  }

  haveInvalidRisks() {
    return false;
  }

  canSubmitInvestigation() {
    return (
      this.incident.status == 'In Progress' &&
      this.incident.causes.length &&
      !this.haveInvalidRisks()
    );
  }
}
