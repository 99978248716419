import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { SiteModel } from 'src/app/models/site.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';

@Component({
  selector: 'app-sites-selector-toolbar',
  templateUrl: './sites-selector-toolbar.component.html',
  styleUrls: ['./sites-selector-toolbar.component.css']
})
export class SitesSelectorToolbarComponent implements OnInit {
  @Output() selected = new EventEmitter();

  selectedSite: SiteModel = new SiteModel();

  constructor(
    public utils: UtilsService,
    // public sitesService: SitesService,
    // public storage: StorageService,
    public app: AppService,
    private api: ApiService
  ) {}

  ngOnInit() {
    // get the current active site id
    // let activeSiteId = this.storage.get('activeSiteId');
    //// depricated code
    // this.sitesService.get(0, true, () => { // getAccessibleSites(...)
    //
    //  if ( !activeSiteId ) {
    //    //this.sitesService.get(0, true, () => { // getAccessibleSites(...)
    //      this.storage.set('activeSiteId', this.sitesService.activeSiteId);
    //      this.app.activeSiteId = this.sitesService.activeSiteId;
    //    //});
    //  } else {
    //    this.sitesService.activeSiteId = activeSiteId;
    //    this.app.activeSiteId = activeSiteId;
    //  }
    // });

    // get the active site id
    this.getActiveSite();
  }

  onChangeActiveSite() {
    // show the site selector
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: [this.app.activeSiteId]
      },
      {},
      (results) => {
        if (typeof results !== 'undefined') {
          this.app.activeSiteId = results[0];
        }

        // get the active site
        this.getActiveSite();

        // the event emitter is used to send back a request to fire another event.
        // in this case it is used to reload data in a list
        this.selected.emit(results);
      }
    );
  }

  /**
   * a method to get an active site id
   *
   * minified version of function in dashboard.component.ts
   */
  private getActiveSite() {
    // get the selected site
    this.api
      .request('get', 'sites/' + this.app.activeSiteId)
      .then((response: any) => {
        if (response.data.length) {
          this.selectedSite.apply(response.data[0]);

          if (!this.app.activeSiteId) {
            this.app.activeSiteId = response.data[0].id;
          }
        } else {
          // get the first available site and use it as the selected site
          this.api.request('get', 'sites?limit=1').then((subresponse: any) => {
            if (subresponse.data.length) {
              // apply the data that was found
              this.selectedSite.apply(subresponse.data[0]);
              // set the new site id
              this.app.activeSiteId = this.selectedSite.id;
            }
          });
        }
      });
  }
}
