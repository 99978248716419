import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import moment from 'moment';
import {NgForm} from '@angular/forms';
import { UtilsService } from '../../shared/utils.service';
import { FolderService } from 'src/app/shared/folder.service';
import { FolderModel } from 'src/app/shared/folder.model';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FileService } from 'src/app/shared/file.service';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-account-folders-edit',
  templateUrl: './account-folders-edit.component.html',
  styleUrls: ['./account-folders-edit.component.scss']
})
export class AccountFoldersEditComponent implements OnInit {
  date: Date;
  now = new Date();
  newFiles: FileList;
  inProgress = false;

  selectedTimezone: string = this.app.account.timezone;

  @Input() public folder: FolderModel;

  // Used to determine which list of document types to show.
  @Input('show_document_types_list_for') show_document_types_list_for: 'account'|'user';

  // Used to populate a list of document types to display.
  documentTypes: string[] = [];

  // Used to show or hide the document visibility field.
  @Input('show_document_visibility') show_document_visibility: boolean;

  constructor(
    private app: AppService,
    public utils: UtilsService,
    private folderService: FolderService,
    private dialogRef: MatDialogRef<AccountFoldersEditComponent>,
    private fileService: FileService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // Determine which list of document types to use.
    if ( this.show_document_types_list_for == 'account' ) {
      this.documentTypes = [...this.utils.getDocumentTypesForAccounts()];
      // Set the default visibility to private.
      this.folder.visibility = 'Private';
    } else {
      this.documentTypes = [...this.utils.getDocumentTypesForUsers()];
    }

    // Check if the document visibility is not set.
    if ( !this.folder.visibility ) {
      // Set the default visibility to private.
      this.folder.visibility = 'Private';
    }

    if ( !this.folder.expiry ) {
      return;
    }
    this.date = new Date(this.folder.expiry * 1000);
  }

  getOutputTimezone($event) {
    this.selectedTimezone = $event;
  }

  onRemove() {
    this.utils.showModal(
      'Remove Folder',
      'Are you sure you want to remove this folder?',
      () => {
        this.folderService.delete([this.folder.id]).subscribe(() => {
          this.utils.showToast('Folder has been deleted.');
          this.dialogRef.close(true);
        });
      }
    );
  }

  /**
   *
   * @param form
   *
   * remember the date_expiry field conversion.
   */
  async onSubmit(form: NgForm) {
    // make sure the form data is valid
    if (form.valid) {
      if (this.inProgress) {
        return;
      }

      this.inProgress = true;
      const folderCopy = {...this.folder};

      // try to upload the files and handle the response gracefully.
      try {
        folderCopy.files = await this.fileService.createManyFromForm(this.newFiles);
      } catch (error) {
        // Reset the in progress status.
        this.inProgress = false;
        return;
      }

      // only set the date if one was selected
      if (this.date) {
        // const date = new Date(this.date);
        const dateToSave = moment.tz(moment(this.date).format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedTimezone);
        // date of incident should take this format 'MM DD, YYYY HH:SS'
        folderCopy.expiry = dateToSave.unix();
      }

      // check if the site should be created or updated
      if (this.folder.id) {
        await this.update(folderCopy);
      } else {
        await this.create(folderCopy);
      }
    } else {
      alert('Please make sure you enter all fields with valid information.');
    }
  }

  onRemoveFiles() {
    const dialogRef = this.dialog.open(FilesRemoveComponent, {
      data: this.folder.files,
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(() => this.findFiles());
  }

  onRemoveExpiryDate() {
    this.folder.expiry = null;
    delete this.date;
  }

  openDocument(url: string) {
    window.open(url, '_blank');
  }

  private findFiles() {
    if (isNaN(this.folder.id)) { return; }
    this.folderService
      .find(this.folder.id)
      .subscribe((response) => (this.folder.files = response.data.files));
  }

  private async create(folder: FolderModel) {
    this.folderService.create(folder).subscribe(
      () => {
        this.utils.showToast('The folder has been created.');
        this.dialogRef.close(true);
        this.inProgress = false;
      },
      (_error) => (this.inProgress = false)
    );
  }

  private async update(folder: FolderModel) {
    this.folderService.update(folder).subscribe(
      () => {
        this.utils.showToast('The folder has been updated.');
        this.inProgress = false;
        this.newFiles = null;
        this.findFiles();
      },
      (_error) => (this.inProgress = false)
    );
  }
}
