import { Routes } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MailchimpComponent } from './mailchimp/mailchimp.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { LoginCredentialsComponent } from './user/login-credentials/login-credentials.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from './guards/auth.guard';
import { HandleQrCodeComponent } from './handle-qr-code/handle-qr-code.component';

export const ROUTES: Routes = [
  { path: '', component: SignInComponent },
  { path: 'about', component: AboutComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'contact-us', component: ContactUsComponent },
  {
    path: 'handle-qr-code/:code_hash',
    component: HandleQrCodeComponent
  },
  {
    path: 'login-credentials',
    component: LoginCredentialsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'release-notes', component: ReleaseNotesComponent },
  {
    path: 'system',
    component: MailchimpComponent,
    canActivate: [
      AuthGuard
    ],
    children: [
      {
        path: 'mailchimp',
        component:
        MailchimpComponent
      }
    ]
  },
  { path: '**', component: PageNotFoundComponent },
  { path: 'not-found', component: PageNotFoundComponent }
];
