import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemModel } from 'src/app/items/item.model';
import { PrequalificationModel } from 'src/app/models/prequalification.model';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { AppService } from 'src/app/app.service';
import moment from 'moment';

@Component({
  selector: 'app-contractors-prequalifications-edit',
  templateUrl: './contractors-prequalifications-edit.component.html',
  styleUrls: ['./contractors-prequalifications-edit.component.scss']
})
export class ContractorsPrequalificationsEditComponent implements OnInit {

  prequalification = new PrequalificationModel();

  items: ItemModel[];

  templates: PrequalificationModel[];

  contractorId: number;

  inProgress: boolean = false;

  currentDate: moment.Moment = moment();
  expiryDate: moment.Moment;

  selectedTimezone: string = this.app.account.timezone;

  constructor(
    private route: ActivatedRoute,
    public utils: UtilsService,
    private router: Router,
    private api: ApiRequestService,
    public app: AppService
  ) {}

  ngOnInit() {

    // this.prequalification.id = Number(this.route.snapshot.params['prequal_id']);
    // if ( this.prequalification.id ) {
    //   this.getPreQualification(this.prequalification.id);
    // }

    this.getPreQualificationTemplates();
    this.getPreQualificationSelectableItems();

    this.contractorId = Number(this.route.snapshot.parent.params.contractor_id);
    if ( this.contractorId ) {
      this.getPreQualification().then(() => {
        this.prequalification.contractor_id = this.contractorId;
      });
    }
  }

  getOutputTimezone($event) {
    this.selectedTimezone = $event;
  }

  private getPreQualificationTemplates() {
    this.api.makeRequest('get', 'v1/prequalifications', {}, { template: true, order_by: 'name', order: 'asc' })
    .then((response) => {
      this.templates = response.data;
    });
  }

  // private getPreQualification(preQualificationId: number) {
  //   return this.api.makeRequest('get', `v1/prequalifications/${preQualificationId}`)
  //   .then((response) => {
  //     this.prequalification = response.data;
  //     this.prequalification.items = this.prequalification.items.map((item) => item.id);
  //   });
  // }

  private getPreQualification() {
    return this.api.makeRequest('get', `v2/contractor-pre-qualifications/${this.contractorId}`)
    .then((response) => {
      this.prequalification = response;
      this.prequalification.items = this.prequalification.items.map((item) => item.id);
      // Set the expiry date
      if ( this.prequalification.expires_at ) {
        this.expiryDate = moment.unix(this.prequalification.expires_at);
      }
    })
    .catch(() => {});
  }

  private getPreQualificationSelectableItems() {
    this.api.makeRequest('get', 'v1/items')
    .then((response) => {
      this.items = response;
    });
  }

  private updateExpiresAtDate() {
    if ( this.expiryDate ) {
      const dateToSave = moment.tz(this.expiryDate.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedTimezone);
      this.prequalification.expires_at = dateToSave.unix();
    } else {
      this.prequalification.expires_at = null;
    }
  }

  onRemoveExpiryDate(evt: any) {
    this.utils.showQuickActions(evt.target, 'Would you like to remove the expiry date? <br>Remember to save after removing it.', [
      {
        text: 'Yes',
        handler: () => {
          this.prequalification.expires_at = null;
          this.expiryDate = null;
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  onSubmit() {

    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    this.prequalification.template = false;

    this.updateExpiresAtDate();

    if ( this.prequalification.id ) {
      this.api.makeRequest('put', `v2/contractor-pre-qualifications/${this.contractorId}`, this.prequalification)
      .then((response) => {
        this.utils.showToast('The Pre-Qualification was successfully updated.');
      }).catch(() => {}).finally(() => this.inProgress = false );
    } else {
      this.api.makeRequest('post', `v2/contractor-pre-qualifications`, this.prequalification)
      .then((response) => {
        this.utils.showToast('The Pre-Qualification was successfully created.');
        this.router.navigate([
          `/contractors`, this.contractorId, 'prequalifications'
        ]);
      }).catch(() => {}).finally(() => this.inProgress = false );
    }
  }

  onDelete() {
    this.utils.showModal(
      'Remove Pre-Qualification',
      `Are you sure you want to remove the "${this.prequalification.name}" Pre-Qualification?`,
      () => {
        this.api.makeRequest('delete', `v2/contractor-pre-qualifications/${this.contractorId}`)
        .then(() => {
          this.utils.showToast('The Pre-Qualification was successfully removed.');
          this.router.navigate(['/contractors', this.contractorId, 'prequalifications']);
        }).catch(() => {});
      }
    );
  }

  onSelectTemplate(template: PrequalificationModel) {
    this.prequalification.name = template.name;
    this.prequalification.description = template.description;
    this.prequalification.items = template.items.map((item) => item.id);
    this.prequalification.enable_notifications = template.enable_notifications;
    this.prequalification.contractor_id = this.contractorId;
  }

  onToggleItem(itemId: number) {
    const index = this.prequalification.items.indexOf(itemId);
    if ( index === -1) {
      this.prequalification.items.push(itemId);
    } else {
      this.prequalification.items.splice(index, 1);
    }
  }

  isItemSelected(itemId: number) {
    if ( this.prequalification.items && this.prequalification.items.length ) {
      return this.prequalification.items.indexOf(itemId) > -1
    } else {
      return false;
    }
  }
}
