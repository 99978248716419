import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../app.service";
import {tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ApiDataSource} from "../../utils/api-data-source";
import {ApiRequestService} from "../../shared/api-request.service";
import {UtilsService} from "../../shared/utils.service";

/**
 * This is a simpler component similar to UserPublicProfileSitesWorkedOnComponent.
 * This component is no longer in use.
 */
@Component({
  selector: 'app-noauth-user-public-profile-sites-worked-on',
  templateUrl: './noauth-user-public-profile-sites-worked-on.component.html',
  styleUrls: ['./noauth-user-public-profile-sites-worked-on.component.scss']
})
export class NoauthUserPublicProfileSitesWorkedOnComponent implements OnInit {

  // The user hash that will be used to get the info.
  @Input() hash: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // The datasource object for list data.
  dataSource = new NoauthUserPublicProfileSitesWorkedOnDataSource(this.app, this.api, true);

  // The columns to display.
  displayedColumns: string[] = [
    'id',
    'owner',
    'name',
    'location'
  ];

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // Store the hash.
    this.dataSource.hash = this.hash;
    // Do not load data if the hash is missing.
    if ( this.dataSource.hash ) {
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }
}

export class NoauthUserPublicProfileSitesWorkedOnDataSource extends ApiDataSource {

  hash: string = '';

  order_by: string = 'name';
  order: string = 'asc';

  getData(resetOffset: boolean = false) {
    if ( this.hash ) {
      this.makeRequest( `v1/noauth/users/${this.hash}/sites-worked-on`, resetOffset);
    }
  }
}
