import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../app.service";
import {UtilsService} from "../../shared/utils.service";
import {ApiRequestService} from "../../shared/api-request.service";
import {tap} from "rxjs/operators";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiDataSource} from "../../utils/api-data-source";
import {CoursesReportFilterComponent} from "../courses-report-filter/courses-report-filter.component";

@Component({
  selector: 'app-courses-report',
  templateUrl: './courses-report.component.html',
  styleUrls: ['./courses-report.component.scss']
})
export class CoursesReportComponent implements OnInit {

  // Columns to show in the view
  displayedColumns: string[] = [
    'accounts.name',
    'users.contact_person',
    'users.email',
    'status'
  ];

  // The data source
  dataSource: CoursesReportDataSource;

  // The paginator and sorter objects
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public app: AppService,
    private api: ApiRequestService,
    public dialogRef: MatDialogRef<CoursesReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils: UtilsService
  ) {

    // set a selected induction id if passed through
    if ( !this.data['id'] ) {
      this.dialogRef.close(false);
      return;
    }

    // Construct the datasource
    this.dataSource = new CoursesReportDataSource(
      this.app,
      this.api
    );

    this.dataSource.selectedInductionId = this.data['id'];

    // Get the data
    this.dataSource.getData();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order_by = this.sort.active;
      this.dataSource.order = this.sort.direction;

      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onUserPublicView(hash: string, event: Event) {
    // Prevent the default events and propagation from triggering.
    event.preventDefault();
    event.stopPropagation();

    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData();
      }
    );
  }

  onFilter() {
    this.utils.showComponentDialog(CoursesReportFilterComponent, {
      account_ids: this.dataSource.filter_account_ids,
      user_ids: this.dataSource.filter_user_ids,
      status: this.dataSource.filter_status
    }, {
      width: '768px'
    }, (results) => {
      if (typeof results !== 'undefined') {
        // Store the account ids.
        this.dataSource.filter_account_ids = results.account_ids;
        // Store the user ids.
        this.dataSource.filter_user_ids = results.user_ids;
        // Store the status.
        this.dataSource.filter_status = results.status;
        // Refresh the list.
        this.dataSource.getData();
      }
    });
  }

  /**
   * Send a request to the API to export induction report as CSV.
   */
  onExport(exportType: string = 'csv') {
    this.api.makeDownloadRequest( `v1/inductions/${this.dataSource.selectedInductionId}/report/export/` + exportType, {}, {
      account_ids: this.dataSource.filter_account_ids.join(','),
      user_ids: this.dataSource.filter_user_ids.join(','),
      status: this.dataSource.filter_status,
      search: this.dataSource.search
    })
      .then((response) => {
        // For IE and Edge browsers.
        if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = `induction-report-${this.dataSource.selectedInductionId}-${date.getFullYear()}${(date.getMonth()+1)}${date.getDate()}.${exportType}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds

      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }
}

/**
 * The networked accounts selector data source.
 */
export class CoursesReportDataSource extends ApiDataSource {

  order_by = 'user_id';
  order = 'asc';

  selectedInductionId: number;

  filter_account_ids: number[] = [];
  filter_user_ids: number[] = [];
  filter_status: string  = "";

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v1/inductions/${this.selectedInductionId}/report`, resetOffset, {
      account_ids: (this.filter_account_ids || []).join(','),
      user_ids: (this.filter_user_ids || []).join(','),
      status: this.filter_status
    });
  }

}
