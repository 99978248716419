import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import moment from 'moment';
import { DynamicFormsAssignedUsersComponent } from 'src/app/dynamic-forms/dynamic-forms-assigned-users/dynamic-forms-assigned-users.component';
import { DynamicFormsEditComponent } from 'src/app/dynamic-forms/dynamic-forms-edit/dynamic-forms-edit.component';
import { DynamicFormModel } from 'src/app/dynamic-forms/dynamic-form.model';
import { DynamicFormsCloneComponent } from 'src/app/dynamic-forms/dynamic-forms-clone/dynamic-forms-clone.component';
import { DynamicFormsFilterComponent } from 'src/app/dynamic-forms/dynamic-forms-filter/dynamic-forms-filter.component';
import {DynamicFormsService} from "../../shared/dynamic-forms.service";
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import {UserModel} from "../../models/user.model";

@Component({
  selector: 'app-sites-dynamic-forms',
  templateUrl: './sites-dynamic-forms.component.html',
  styleUrls: ['./sites-dynamic-forms.component.scss']
})
export class SitesDynamicFormsComponent implements OnInit {

  displayedColumns: string[] = [
    'select',
    'id',
    'title',
    'form_type',
    'is_active',
    'users_count',
    'const_sites_count',
    'status',
    'verified',
    'date_created',
    // 'date_created_UTC',
    'date_modified',
    // 'date_modified_UTC',
    'actions'
  ];

  dataSource = new SitesDynamicFormsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('importFormStructureFileInput') importFormStructureFileInput: ElementRef;

  parent_id: number;
  child_id: number;
  site_id: number;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public renderer: Renderer2,
    private route: ActivatedRoute,
    public dynamicFormsService: DynamicFormsService
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    this.parent_id = Number(this.route.parent.snapshot.params['parent_id']);
    this.child_id = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( this.child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && this.parent_id) ) {
      // Store the site id.
      this.site_id = this.child_id ? this.child_id : this.parent_id;
      // Add the site to the data source filters.
      if ( this.site_id ) {
        this.dataSource.site_ids = [this.site_id];
      }
      // Get the forms.
      this.dataSource.getData(true);
    }
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting takes place
    this.sort.sortChange.subscribe(() => {
      this.paginator1.pageIndex = 0;
      this.paginator2.pageIndex = 0;
    });

    const paginatorTap = tap((paginator) => {
      this.paginator1.pageIndex = paginator['pageIndex'];
      this.paginator1.pageSize = paginator['pageSize'];
      this.paginator2.pageIndex = paginator['pageIndex'];
      this.paginator2.pageSize = paginator['pageSize'];

      this.dataSource.limit = paginator['pageSize'];
      this.dataSource.offset = paginator['pageIndex'];
      this.dataSource.getData();
    });

    // Subscribe to the paginator tap events.
    this.paginator1.page.pipe(paginatorTap).subscribe();
    this.paginator2.page.pipe(paginatorTap).subscribe();

    // Subscribe to the sorter tap events.
    this.sort.sortChange.pipe(tap((sorter) => {
      this.dataSource.order_by = sorter['active'];
      this.dataSource.order = sorter['direction'];

      // Sort UTC date by it's corresponding date column.
      if ( sorter['active'] == 'date_created_UTC' ) {
        this.dataSource.order_by = 'date_created';
      }

      // Sort UTC date by it's corresponding date column.
      if ( sorter['active'] == 'date_modified_UTC' ) {
        this.dataSource.order_by = 'date_modified';
      }

      this.dataSource.getData(true);
    })).subscribe();
  }

  /**
   * Open a dialog that will show a list of all assigned users.
   * @param form_id The form id.
   */
  onViewResponses(form_id: number = 0) {
    // Open the assigned user list in a dialog.
    this.utils.showComponentDialog(DynamicFormsAssignedUsersComponent, {
      form_id: form_id
    }, {
      minWidth: '80%'
    })
    .then(() => {
      // Refresh the list when the dialog closes.
      this.dataSource.getData();
    });
  }

  /**
   * Open the form for editing in order to make changes.
   * @param form_id The form id.
   */
  onEdit(form_id: number = 0) {
    // Open the form editor in a popup dialog.
    this.utils.showComponentDialog(DynamicFormsEditComponent, {
      form_id: form_id,
      site_id: this.site_id
    }, {
      minWidth: '90%',
      minHeight: '90%',
      disableClose: true
    })
    .then(() => {
      // Refresh the list when the dialog closes.
      this.dataSource.getData();
    });
  }

  /**
   * Archive a single form.
   * @param form The form data.
   * @param evt The html element reference.
   */
  onArchive(form: DynamicFormModel, evt: any) {
    // Show a quick actions dialog with options for the user to confirm archiving the form.
    this.utils.showQuickActions(evt.target, `Are you sure you want to archive the "${form.title}" form?`, [
      {
        text: 'Yes',
        handler: () => {
          // Archive the form.
          this.archiveSelected([form.id]);
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  /**
   * Archive the selected forms.
   */
  onArchiveSelected() {
    // Show a confirmation dialog before archiving the selected forms.
    this.utils.showModal(
      'Archive Selected Forms',
      'Are you sure you want to archive the selected forms?',
      () => {
        const archived = this.archiveSelected(this.dataSource.selection.selected);
        if ( archived != null ) {
          archived.then(() => {
            // Clear the selection list.
            this.dataSource.selection.clear();
          });
        }
      }
    );
  }

  /**
   * Archive the forms.
   * @param formIds Form ids to submit for archiving.
   * @returns
   */
  private archiveSelected(formIds: number[]): null|Promise<any> {
    if ( !formIds.length ) {
      this.utils.showModal('Archiving Selected Forms', 'You need to select at least one form to archive.');
      return;
    }
    return this.api.makeRequest('delete', `v2/dynamic-forms/${formIds.join(',')}`)
    .then(() => {
      // Refresh the list after archiving the forms.
      this.dataSource.getData();
      this.utils.showToast('The selected forms were archived.');
    })
    .catch((error) => {
      this.utils.handleAPIErrors(error);
    });
  }

  /**
   * Restore a single form.
   * @param form The form data.
   * @param evt The html element reference where this click event came from.
   */
  onRestore(form: DynamicFormModel, evt: any) {
    this.utils.showQuickActions(evt.target, `Are you sure you want to restore the "${form.title}" form?`, [
      {
        text: 'Yes',
        handler: () => {
          this.restoreSelected([form.id]);
        }
      },
      {
        text: 'No',
        handler: () => {}
      }
    ]);
  }

  /**
   * Restore selected forms.
   */
  onRestoreSelected() {
    // Show a confirmation dialog before restoring selected forms.
    this.utils.showModal(
      'Restore Selected Forms',
      'Are you sure you want to restore the selected forms?',
      () => {
        const request = this.restoreSelected(this.dataSource.selection.selected);
        if ( request != null ) {
          request.then(() => {
            // Refresh the list after restoring forms.
            this.dataSource.selection.clear();
          });
        }
      }
    );
  }

  /**
   * Restores forms by id.
   * @param formIds The form ids to submit for restoration.
   * @returns
   */
  private restoreSelected(formIds: number[]): null|Promise<any> {
    if ( !formIds.length ) {
      this.utils.showModal('Restoring Selected Forms', 'You need to select at least one form to restore.');
      return;
    }
    return this.api.makeRequest('put', `v2/dynamic-forms/restore/${formIds.join(',')}`)
    .then(() => {
      // Refresh the list after restoring forms.
      this.dataSource.getData();
      this.utils.showToast('The selected forms were restored.');
    })
    .catch((error) => {
      this.utils.handleAPIErrors(error);
    });
  }

  /**
   * Export the selected forms captured data into a PDF or CSV for reporting.
   * Duplicated function of dynamic-forms-assigned.component.ts
   * @returns
   */
   onExportFormsData(export_type: string = 'pdf', export_submitted_forms: boolean = false) {
    // Check if there are any forms selected.
    if ( this.dataSource.selection.selected.length == 0 ) {
      this.utils.showModal('Export Form Structures', 'You need to select forms to export.');
      return;
    }

    // If export submitted forms is true then we want to export forms for all assigned users.
    const params = {};
    if ( export_submitted_forms ) {
      params['user_ids'] = 'all';
    }

    // If we are exporting a pdf document and for all assigned users, it should be executed as a background task.
    if ( export_type == 'pdf' && export_submitted_forms ) {
      // Generating PDF exports takes longer than generating CSV exports.
      // It is therefore executed as a background task instead and emailed to the user that made the request.
      // Make a simple get request to start the exporting process.
      this.api.makeRequest('get', `v2/dynamic-forms/export-data/${export_type}/${this.dataSource.selection.selected.join(',')}`, {}, params)
      .then((response) => {
        this.utils.showToast(response.message);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    } else {
      // Make an API download request to get the form structures.
      // Empty PDF forms and CSV exports are much faster that populated PDF exports.
      this.api.makeDownloadRequest(`v2/dynamic-forms/export-data/${export_type}/${this.dataSource.selection.selected.join(',')}`, {}, params)
      .then((response) => {
        // Ask the browser to save the file download response.
        saveAs(response, 'Exported Forms - ' + moment().format('DD-MM-YYYY') + '.zip');
      })
      .catch((errorResponse) => {
        // Show a standard error message.
        this.utils.showModal('Form Data Export Error', 'We could not export the data for your selected forms. Please try again and contact support if the issue persists.');
      });
    }
  }

  /**
   * Export the selected forms into a JSON file for re-import.
   * @returns
   */
   onExportFormStructures() {
    // Check if there are any forms selected.
    if ( this.dataSource.selection.selected.length == 0 ) {
      this.utils.showModal('Export Form Structures', 'You need to select forms to export.');
      return;
    }
    // Make an API download request to get the form structures.
    this.api.makeDownloadRequest(`v2/dynamic-forms/export/${this.dataSource.selection.selected.join(',')}`)
    .then((response) => {
      // Ask the browser to save the file download response.
      saveAs(response, 'Form Structures - ' + moment().format('YYYY-MM-DD') + '.json');
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  /**
   * Trigger the form structure import file selector click event.
   * @param evt
   */
  onTriggerImportFormStructureFileSelector(evt: Event) {
    if(this.importFormStructureFileInput) {
      const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
      this.importFormStructureFileInput.nativeElement.dispatchEvent(clickEvt);
    }
  }

  /**
   *
   * @param evt The form structure import file selector element reference.
   */
  onImportFormStructures(evt: any) {
    // Check if any files were selected.
    if ( evt.target.files.length == 0 ) {
      this.utils.showModal('Form Structure Import', 'You need to select at least one JSON formatted file to import.');
      return;
    }

    // TBD: Validate that JSON files are selected.

    const files: any = [];
    for ( let i = 0; i < evt.target.files.length; i++ ) {
      files.push(evt.target.files[i]);
    }

    // Show the user a confirmation dialog before importing the file.
    this.utils.showModal('Import Form Structures', 'Are you sure you want to import the form structures from your selected files?', () => {
      // Upload the selected files to import.
      this.api.makeUploadRequest(`v2/dynamic-forms/import`, files, {
        site_id: this.site_id
      })
      .then((response) => {
        this.utils.showToast(response);
        this.dataSource.getData(true);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
    });

    // clear the target value
    evt.target.value = '';
  }

  /**
   * Open the form clone component for the user to update the form title and description before cloning.
   * @param form The form data.
   */
   onClone(form: DynamicFormModel) {
    this.utils.showComponentDialog(DynamicFormsCloneComponent, {
      form_id: form.id,
      form_title: form.title,
      form_description: form.description,
      site_id: this.site_id
    }, {
      width: '600px'
    })
    .then((response) => {
      // If the form was cloned successfully, open it for editing.
      if ( typeof response != 'undefined' && response.id ) {
        this.onEdit(response.id);
      }
      // Always refresh the list.
      this.dataSource.getData();
    });
  }

  /**
   * Open the list filters.
   */
   onOpenFilters() {
    this.utils.showComponentDialog(DynamicFormsFilterComponent, {
      only_archived: this.dataSource.only_archived,
      category_ids: this.dataSource.category_ids,
      site_ids: this.dataSource.site_ids,
      contractor_ids: this.dataSource.contractor_ids,
      user_ids: this.dataSource.user_ids,
      industry_ids: this.dataSource.industry_ids,
      trade_ids: this.dataSource.trade_ids
    }, {
      width: '350px'
    })
    .then((response) => {
      if ( response ) {
        this.dataSource.only_archived = response.only_archived;
        this.dataSource.category_ids = response.category_ids;
        this.dataSource.site_ids = response.site_ids;
        this.dataSource.contractor_ids = response.contractor_ids;
        this.dataSource.user_ids = response.user_ids;
        this.dataSource.industry_ids = response.industry_ids;
        this.dataSource.trade_ids = response.trade_ids;

        // Restore the original site id if none is selected.
        if ( response.site_ids.length == 0 ) {
          this.dataSource.site_ids = [this.site_id];
        }

        // Get the forms.
        this.dataSource.getData(true);
      }
    });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

  /**
   * Validates and checks what the status of the form submission is. It then
   * returns a corresponding color e.g.
   * - red = Assigned or Rejected.
   * - orange/yellow = In Progress or Work in Progress.
   * - green = Submitted or Completed.
   * - blue = Approved.
   * @param user The user data to validate and check.
   * @returns
   */
  getFormSubmissionStatusColors(user: UserModel) {
    return {
      'text-danger': user && user.pivot && user.pivot.dynamic_form_status && ['Assigned', 'Rejected'].indexOf(user.pivot.dynamic_form_status) > -1,
      'text-warning': user && user.pivot && user.pivot.dynamic_form_status && ['In Progress', 'Approved: Work in Progress'].indexOf(user.pivot.dynamic_form_status) > -1,
      'text-success': user && user.pivot && user.pivot.dynamic_form_status && ['Submitted', 'Work Completed'].indexOf(user.pivot.dynamic_form_status) > -1,
      'text-info': user && user.pivot && user.pivot.dynamic_form_status && ['Approved'].indexOf(user.pivot.dynamic_form_status) > -1
    };
  }

}

export class SitesDynamicFormsDataSource extends ApiDataSource {

  order_by = 'id';
  order = 'desc';

  category_ids: number[] = [];

  site_ids: number[] = [];

  contractor_ids: number[] = [];

  user_ids: number[] = [];

  industry_ids: number[] = [];

  trade_ids: number[] = [];

  getData(resetOffset: boolean = false) {
    this.makeRequest(`v2/dynamic-forms`, resetOffset, {
      only_templates: false,
      category_ids: this.category_ids.join(','),
      site_ids: this.site_ids.join(','),
      contractor_ids: this.contractor_ids.join(','),
      user_ids: this.user_ids.join(','),
      industry_ids: this.industry_ids.join(','),
      trade_ids: this.trade_ids.join(',')
    });
  }
}
