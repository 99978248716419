<div class="app-margin">

  <h6 *ngIf="assignee.id">Investigator...</h6>
  <p>{{ assignee.contact_person }}</p>

  <div *ngIf="reviewer.id">
    <h6>Reviewer</h6>
    <p>{{ reviewer.contact_person }}</p>
  </div>

  <button type="button" mat-raised-button color="secondary" (click)="onSelectInvestigator()"
    [matBadge]="'' + (assignee.id ? 1 : 0)" class="margin-right"
    matTooltip="An assignee is required for an investigation to begin">
    <mat-icon>person</mat-icon>
    {{ !assignee.id ? 'Select' : 'Change' }} Investigator
  </button>

  <button type="button" mat-raised-button color="secondary" (click)="onSelectReviewer()"
    [matBadge]="'' + (reviewer.id ? 1 : 0)"
    matTooltip="You can optionally select a reviewer.">
    <mat-icon>rate_review</mat-icon>
    {{ !reviewer.id ? 'Select' : 'Change' }} Reviewer
  </button>
</div>
