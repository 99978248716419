import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { ApiService } from '../shared/api.service';
import { UtilsService } from '../shared/utils.service';
import { CustomDataSource } from '../utils/custom-data-source';
import { FolderModel } from '../shared/folder.model';
import { FolderService } from '../shared/folder.service';
import { FoldersViewComponent } from './folders-view/folders-view.component';
import {AccountFoldersTabsComponent} from "../account/account-folders-tabs/account-folders-tabs.component";
import {MatDialog} from "@angular/material/dialog";
import {SiteModel} from "../models/site.model";

@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new LibraryDataSource(this.app, this.api);
  displayedColumns = [
    'select',
    'id',
    'name',
    'media',
    'links',
    'sites',
    'expiry',
    'created_at',
    // 'created_at_UTC',
    'buttons'
  ];

  constructor(
    public utils: UtilsService,
    private folderService: FolderService,
    private app: AppService,
    private api: ApiService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataSource.getData(false);
  }

  add() {
    this.edit({ template: true } as FolderModel);
  }

  view(folder: FolderModel) {
    this.dialog
      .open(FoldersViewComponent, {
        width: '750px',
        data: {
          folder: folder,
          is_training_doc: false
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData(true);
      });
  }

  edit(folder: FolderModel) {
    this.dialog
      .open(AccountFoldersTabsComponent, {
        width: '1024px',
        data: {
          folder: folder,
          is_training_doc: false,
          show_document_types_list_for: 'account',
          show_document_visibility: false
        }
      })
      .afterClosed()
      .subscribe((result) => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onRemove(folder: FolderModel) {
    this.utils.showModal(
      'Remove Folder',
      `Are you sure you want to remove "${folder.name}"?`,
      () => {
        this.remove([folder.id]);
      }
    );
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Remove Folders',
      `Are you sure you want to remove ${this.dataSource.selection.selected.length} Folders?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  private remove(ids: number[]) {
    this.folderService.delete(ids).subscribe(() => {
      this.utils.showToast('Removed Folder(s)');
      this.dataSource.selection.clear();
      this.dataSource.getData(true);
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.sort_by = this.sort.active;
      this.dataSource.sort_order = this.sort.direction;

      // Sort UTC columns by their corresponding date columns.
      if ( this.sort.active == 'created_at_UTC' ) {
        this.dataSource.sort_by = 'created_at';
      }

      this.dataSource.getData(false);
    });
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  /**
   * Format the text display for associated sites.
   * Examples:
   *  - Head Office
   *  - 0 Sites
   *  - 1 Site (If name is not accessible otherwise the name of the site)
   *  - 2 Sites
   *  - Etc.
   * @param form
   */
  formatSitesListColumnText(document: FolderModel) {
    // Check if we have one user.
    if ( document.sites_count == 1 ) {
      // Check if we have details of one user.
      if ( document.sites[0] ) {
        return document.sites[0].name;
      }
      // Respond with a generic 1 User response.
      return document.sites_count + ' ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site');
    }
    // Respond with the users count.
    return document.sites_count + ' ' + this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites');
  }

  /**
   * The tooltip text is only used if there is more than 1 site.
   * @param form
   */
  formatSitesListColumnTooltip(document: FolderModel) {
    if ( document.sites_count > 1 ) {
      const site_names: string[] = document.sites.map((site: SiteModel) => {
        return site.name;
      });
      return site_names.join(`\n`);
    }
    return '';
  }
}

export class LibraryDataSource extends CustomDataSource {
  sort_by = 'name';
  sort_order = 'asc';

  getData(resetOffset = false) {
    this.getDataFromLaravelAPI(
      `folders?template=true&eager_load=true`,
      resetOffset
    );
  }
}
