import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { ItemModel } from '../items/item.model';
import { FileModel } from '../shared/file.model';
import { ItemPivotModel } from '../items/item-pivot.model';
import { MatDialog } from '@angular/material/dialog';
import { FilesRemoveComponent } from '../files/files-remove/files-remove.component';
import { AppService } from '../app.service';
import { UtilsService } from '../shared/utils.service';
import { NoauthService } from '../shared/noauth.service';
import { ActivatedRoute } from '@angular/router';
import { MyItemsNotesComponent } from './my-items-notes/my-items-notes.component';
import moment from 'moment';

@Component({
  selector: 'app-my-items',
  templateUrl: './my-items.component.html',
  styleUrls: ['./my-items.component.scss']
})
export class MyItemsComponent implements OnInit, AfterViewInit {
  @Input() hash: string;
  @Input() items: ItemModel[];
  @Input() edit: boolean;
  @Output() edited = new EventEmitter();
  newFiles: { [id: number]: FileList } = {};
  expiries: { [id: number]: moment.Moment } = {};
  inProgresses: { [id: number]: boolean} = {};

  constructor(
    public app: AppService,
    private noauthService: NoauthService,
    private dialog: MatDialog,
    private utils: UtilsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    for (let i = 0; i < this.items.length; i++) {
      const pivot = this.items[i].pivot;
      if (!pivot.expiry) { continue; }
      this.expiries[pivot.id] = moment.unix(pivot.expiry);
    }
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      if (!fragment) { return; }
      document.getElementById(fragment).scrollIntoView();
    });
  }

  onAddNotes(_item: ItemModel) {
    this.utils.showComponentDialog(
      MyItemsNotesComponent,
      { item: _item },
      { width: '700px' },
      (notes: string) => {
        if (!notes) { return; }
        this.addNotes(_item.pivot.id, notes);
      }
    );
  }

  // Redo the entire feature.
  async onUpdateAnswer(item: ItemModel) {
    await this.noauthService.updateItemPivot(item.pivot.id, this.hash, {
      answer: item.pivot.answer
    } as ItemPivotModel);
    this.utils.showToast('Answer updated.');
  }

  private async addNotes(pivotId: number, _notes: string) {
    await this.noauthService.updateItemPivot(pivotId, this.hash, {
      notes: _notes
    } as ItemPivotModel);
    this.utils.showToast('Notes updated.');
  }

  async onSetExpiry(pivotId: number, itemName: string, shouldRemoveExpiryDate: boolean = false) {
    const expiry = this.expiries[pivotId];

    await this.noauthService.updateItemPivot(pivotId, this.hash, {
      expiry: shouldRemoveExpiryDate ? null : expiry.unix()
    } as ItemPivotModel);

    if ( shouldRemoveExpiryDate ) {
      this.utils.showToast(`The expiry date for "${itemName}" was removed.`);
      this.expiries[pivotId] = null;
    } else {
      this.utils.showToast(`The expiry date for "${itemName}" was set to expire at ${expiry.format("DD/MM/YYYY")}.`);
    }
  }

  async onUpload(index: number) {
    if (this.inProgresses[index]) {
      return;
    }

    this.inProgresses[index] = true;
    try {
      const item = this.items[index];
      const pivotId = item.pivot.id;
      const createdFiles = await this.noauthService.createFiles(
        this.newFiles[pivotId],
        this.hash
      );
      await this.noauthService.updateItemPivot(pivotId, this.hash, {
        files: createdFiles
      } as ItemPivotModel);

      // Lets parent components know to reload.
      this.edited.emit();

      // Prevents people accidentally uploading twice.
      this.newFiles[pivotId] = null;

      this.nextOrLastElement(item, index);
    } finally {
      this.inProgresses[index] = false;
    }
  }

  onRemoveFiles(files: FileModel[]): void {
    const dialogRef = this.dialog.open(FilesRemoveComponent, {
      data: files,
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(() => this.edited.emit());
  }

  private nextOrLastElement(item: ItemModel, index: number) {
    if (this.items.length === index + 1) { return; }
    const nextItem = this.items[index + 1];
    document
      .getElementById(nextItem.id.toString())
      .scrollIntoView({ behavior: 'smooth' });
    this.utils.showToast(`Completed item ${item.name}`);
  }
}
