import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output, Renderer2,
  Self,
  ViewChild
} from '@angular/core';
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgControl, NgForm, Validators} from "@angular/forms";
import {UtilsService} from "../utils.service";
import {MatFormFieldControl} from "@angular/material/form-field";
import {Subject} from "rxjs";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import latestMeta from 'moment-timezone/data/meta/latest.json';

class MyTel {
  constructor(public number: string, public countryCode: string, public dialCode: string, public e164Number: string) {}
}

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: PhoneInputComponent}]
})
export class PhoneInputComponent implements MatFormFieldControl<MyTel> {

  // Mobile field Country Code selector
  mobileMaxLength = "20"; // initial value: 15
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.NewZealand, CountryISO.Australia, CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  selectedCountry = CountryISO.NewZealand;  // Default is New Zealand
  lastNumber = '';
  lastCountryCode = '';
  lastDialCode = '';
  inputClass = 'custom-phone-input';
  initNumber = '';
  initCountryCode = '';
  initDialCode = '';
  initE164 = '';

  // timeZoneToCountry = {};
  timeZoneCityToCountry = {};
  userRegion = '';
  userCity = '';
  userCountry = '';
  userTimeZone = '';

  @Input() idInput: string;
  @Input() numberInput: string;
  @Input() countryCodeInput: string;
  @Input() dialCodeInput: string;
  @Input() e164NumberInput: string;
  @Input() additionalClassInput: string;

  @Output() phoneDetailsOutput: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('phoneInput') tagInput: ElementRef<HTMLInputElement>;

  stateChanges = new Subject<void>();
  static nextId = 0;
  focused = false;
  errorState = false;
  disableState = false;
  disableCountryCodeSelector = false;
  controlType = 'phone-input';

  @HostBinding() id = `phone-input-${PhoneInputComponent.nextId++}`;
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    // return this.focused || !this.empty;
    return true; // Always float
  }

  @Input()
  get value(): MyTel | null {
    let n = this.phoneForm.value;
    if (n.phone.number.length && n.phone.countryCode.length && n.phone.dialCode.length) {
      return new MyTel(n.phone.number, n.phone.countryCode, n.phone.dialCode, n.phone.e164Number);
    }
    return null;
  }
  set value(tel: MyTel | null) {
    tel = tel || new MyTel('', '', '', '');
    this.phoneForm.setValue({ phone : {
        number: tel.number, countryCode: tel.countryCode, dialCode: tel.dialCode, e164Number: tel.e164Number
    }});
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required() {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    // this._disabled ? this.phoneForm.disable() : this.phoneForm.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  get empty() {
    let n = this.phoneForm.value;
    if(n.phone && n.phone.hasOwnProperty('number')) {
      return !n.phone.number;
    }
    return true;
  }

  // Not working as it should and removed for further testing
  // get errorState(): boolean {
  //   return this.phoneForm.invalid && this.touched;
  // }

  @Input('aria-describedby') userAriaDescribedBy: string;

  // Not required
  setDescribedByIds(ids: string[]) {
    // const controlElement = this._elementRef.nativeElement
    //   .querySelector('.phone-input-container')!;
    // controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  // Not required but event is triggered when clicking on input
  onContainerClick(event: MouseEvent) {
    // if ((event.target as Element).tagName.toLowerCase() != 'input') {
    //   this._elementRef.nativeElement.querySelector('input').focus();
    // }
  }

  constructor(
    public utils: UtilsService,
    private fb: FormBuilder,
    private elem: ElementRef,
    private renderer: Renderer2,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective
  ) {
    this.phoneForm = this.fb.group({
      phone: {
        number: '',
        countryCode: '',
        dialCode: '',
        e164Number: ''
      }
    });
  }

  ngOnInit() {
    this.timezoneChecker();

    this.phoneForm.value.phone.number = this.numberInput ? this.numberInput : '';
    this.phoneForm.value.phone.countryCode = this.countryCodeInput ? this.countryCodeInput : '';
    this.phoneForm.value.phone.dialCode = this.dialCodeInput ? this.dialCodeInput : '';
    this.phoneForm.value.phone.e164Number = this.e164NumberInput ? this.e164NumberInput : '';

    this.setInitVal();

    if(this.additionalClassInput && (this.additionalClassInput.includes('grey') || this.additionalClassInput.includes('compact'))) {
      this.inputClass = this.inputClass + ' ' + this.additionalClassInput;
    }
  }

  ngDoCheck() {
    this.disableSelectorCheck();
    this.disableSelector(this.idInput);

    // Triggers when Country Code selector and input value is changed
    this.phoneForm.valueChanges.subscribe(() => {
      this.updateErrorState();
      this.updateDisableState();
      this.updatePhoneDetails();
    });
  }

  ngOnChanges() {
    // Triggers when Input Values from Parent component is changed
    if(this.setInitVal()) {
      this.phoneForm = this.fb.group({
        phone: {
          number: this.numberInput ? this.numberInput : '',
          countryCode: this.countryCodeInput ? this.countryCodeInput : '',
          dialCode: this.dialCodeInput ? this.dialCodeInput : '',
          e164Number: this.e164NumberInput ? this.e164NumberInput : ''
        }
      });

      this.updateErrorState();
      this.updateDisableState();
      this.updatePhoneDetails();
    }
  }

  // Set Initial Input Values from Parent component
  private setInitVal() {
    let changed = false;
    if(!this.numberInput && this.initNumber != '' || (this.numberInput && this.initNumber != this.numberInput)) {
      this.initNumber = this.numberInput ? this.numberInput : '';
      changed = true;
    }

    if(!this.countryCodeInput && this.initCountryCode != '' || (this.countryCodeInput && this.initCountryCode != this.countryCodeInput)) {
      this.initCountryCode = this.countryCodeInput ? this.countryCodeInput : '';
      changed = true;
    }

    if(!this.dialCodeInput && this.initDialCode != '' || (this.dialCodeInput && this.initDialCode != this.dialCodeInput)) {
      this.initDialCode = this.dialCodeInput ? this.dialCodeInput : '';
      changed = true;
    }

    if(!this.e164NumberInput && this.initE164 != '' || (this.e164NumberInput && this.initE164 != this.e164NumberInput)) {
      this.initE164 = this.e164NumberInput ? this.e164NumberInput : '';
      changed = true;
    }

    return changed;
  }

  // Triggers when Country Selector is used
  public onChange($event) {
    // this.phoneForm.valueChanges.subscribe(() => {
      // add code
    // });
  }

  // Detects User Country and changes selected Country Code
  private timezoneChecker() {
    Object.keys(latestMeta.zones).forEach(z => {
      // this.timeZoneToCountry[z] = latestMeta.countries[zones[z].countries[0]].name;
      const cityArr = z.split("/");
      const city = cityArr[cityArr.length-1];
      this.timeZoneCityToCountry[city] = latestMeta.countries[latestMeta.zones[z].countries[0]].name;
    });

    if (Intl) {
      this.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let tzArr = this.userTimeZone.split("/");
      this.userRegion = tzArr[0];
      this.userCity = tzArr[tzArr.length - 1];
      this.userCountry = this.timeZoneCityToCountry[this.userCity];

      let userCountryTrimmed = this.userCountry.replace(/\s/g, '');
      if(this.CountryISO[userCountryTrimmed]) {
        this.selectedCountry = this.CountryISO[userCountryTrimmed];
      }
    }
  }

  // Checks if Country Code Selector should be disabled
  private disableSelectorCheck() {
    const isPhoneAvailable = this.phoneForm.value.phone;
    if(isPhoneAvailable) {
      const phoneNumber = this.phoneForm.value.phone.number;
      this.disableCountryCodeSelector = phoneNumber.length > 0 && Array.from(phoneNumber)[0] == '+'; // If the first character of a number is '+' then it includes a dial code
    } else {
      this.disableCountryCodeSelector = false;
    }
  }

  // Disables Country Code Selector
  private disableSelector(inputId: string) {
    if(inputId && inputId.trim() != "") {
      const dropdowns = this.elem.nativeElement.querySelectorAll('.iti--allow-dropdown');
      let dropdownToggle = null;
      let dropdownMenu = null;

      for (let i = 0; i < dropdowns.length; ++i) {
        let parentInputId = dropdowns[i].parentNode.getAttribute('ng-reflect-input-id');
        if (parentInputId == inputId) {
          // Continue with disabling selector
          const dropdownElements = Array.from(dropdowns[i].childNodes[0].childNodes) as HTMLElement[];
          dropdownToggle = dropdownElements[0];

          // When Dropdown Menu is rendered
          if (dropdowns[i].childNodes[0].childNodes.length > 2) {
            dropdownMenu = dropdowns[i].childNodes[0].childNodes[2];
          }

          break;
        }
      }

      if(this.disableCountryCodeSelector) {
        // Change to Disabled Toggle opacity
        if(dropdownToggle) {
          dropdownToggle.style.opacity = '0.5';
        }

        // Hide Dropdown menu
        if(dropdownMenu) {
          if(dropdownMenu.classList.contains('show')) {
            this.renderer.removeClass(dropdownMenu, 'show');
          }
        }
      } else if (dropdownToggle) {
        // Change to Active Toggle opacity
        dropdownToggle.style.opacity = '1';
      }
    }
  }

  // Updates Phone details when data is changed
  private updatePhoneDetails() {
    const isPhoneAvailable = this.phoneForm.value.phone;
    let phoneChanged = false;

    let currentNumber = isPhoneAvailable ? this.phoneForm.value.phone.number : '';
    if(currentNumber != this.lastNumber) {
      this.lastNumber = currentNumber;
      if(!phoneChanged) {
        phoneChanged = true;
      }
    }

    let currentCountryCode = isPhoneAvailable ? this.phoneForm.value.phone.countryCode : '';
    if(currentCountryCode != this.lastCountryCode) {
      this.lastCountryCode = currentCountryCode;
      if(!phoneChanged) {
        phoneChanged = true;
      }
    }

    let currentDialCode = isPhoneAvailable ? this.phoneForm.value.phone.dialCode : '';
    if(currentDialCode != this.lastDialCode) {
      this.lastDialCode = currentDialCode;
      if(!phoneChanged) {
        phoneChanged = true;
      }
    }

    if(phoneChanged) {
      this.outputPhoneDetails();
    }
  }

  // Updates Error state when data is changed
  private updateErrorState() {
    const oldState = this.errorState;

    const isPhoneAvailable = this.phoneForm.value.phone;
    const valueLength = isPhoneAvailable ? this.phoneForm.value.phone.number.length : 0;
    const isPhoneLengthInvalid = isPhoneAvailable && valueLength <= 2;
    const isPhoneLengthNonZero = isPhoneAvailable && valueLength > 0;
    const newState = this._required ? (isPhoneAvailable && isPhoneLengthInvalid) : (isPhoneAvailable && isPhoneLengthNonZero && isPhoneLengthInvalid);

    if(oldState === null || newState === null) {
      // When there is no value in input and the field is required it will automatically set the error state to true
      this.errorState = this._required;
      this.stateChanges.next();
    } else if (oldState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  // Updates Disabled state when data is changed
  private updateDisableState() {
    let changed = false;

    if(this.disableState != this._disabled) {
      this.disableState = this._disabled;
      changed = true;
    }

    if(this._disabled && changed) {
      this.phoneForm.disable();
    } else if (changed) {
      this.phoneForm.enable();
    }
  }

  private outputPhoneDetails() {
    const phoneDetails = {
      errorState: this.errorState,
      ...this.phoneForm.value.phone
    };
    this.phoneDetailsOutput.emit(phoneDetails);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
}
