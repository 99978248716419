import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MATERIAL_MODULES } from '../app.const';
import { ItemsEditComponent } from './items-edit/items-edit.component';
import { ItemsRoutingModule } from './items-routing.module';
import { ItemsViewComponent } from './items-view/items-view.component';
import { ItemsComponent } from './items.component';
import { PipesModule } from '../pipes/pipes.module';
import { FilesModule } from '../files/files.module';
import {DirectivesModule} from "../directives/directives.module";

@NgModule({
  declarations: [ItemsComponent, ItemsEditComponent, ItemsViewComponent],
    imports: [
        CommonModule,
        FormsModule,
        ItemsRoutingModule,
        MATERIAL_MODULES,
        PipesModule,
        FilesModule,
        DirectivesModule
    ],
  exports: [ItemsComponent]
})
export class ItemsModule {}
