import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from 'src/app/shared/utils.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { FolderModel } from 'src/app/shared/folder.model';
import { FolderService } from 'src/app/shared/folder.service';
import { SiteModel } from 'src/app/models/site.model';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';
import { FileService } from 'src/app/shared/file.service';
import { AppService } from '../../app.service';
import moment from 'moment';

@Component({
  selector: 'app-folders-edit',
  templateUrl: './folders-edit.component.html',
  styleUrls: ['./folders-edit.component.scss']
})
export class FoldersEditComponent implements OnInit {
  isAllSelected: boolean;
  newFiles: FileList;
  inProgress = false;

  @Input() public folder: FolderModel;

  selectedTimezone: string = this.app.account.timezone;

  date: Date;
  now = new Date();

  // Used to determine which list of document types to show.
  @Input('show_document_types_list_for') show_document_types_list_for: 'account'|'user';

  // Used to populate a list of document types to display.
  documentTypes: string[] = [];

  // Used to show or hide the document visibility field.
  @Input('show_document_visibility') show_document_visibility: boolean;

  constructor(
    private app: AppService,
    public utils: UtilsService,
    private folderService: FolderService,
    private dialogRef: MatDialogRef<FoldersEditComponent>,
    private dialog: MatDialog,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    // Determine which list of document types to use.
    if ( this.show_document_types_list_for == 'account' ) {
      this.documentTypes = [...this.utils.getDocumentTypesForAccounts()];
      // Set the default visibility to private.
      this.folder.visibility = 'Private';
    } else {
      this.documentTypes = [...this.utils.getDocumentTypesForUsers()];
    }

    // Check if the document visibility is not set.
    if ( !this.folder.visibility ) {
      // Set the default visibility to private.
      this.folder.visibility = 'Private';
    }

    // Check if the folder has an expiry date set.
    if ( this.folder.expiry && Number(this.folder.expiry) > 0 ) {
      // Create a new date object from the expiry date. The expiry date is stored as a unix timestamp.
      this.date = new Date(this.folder.expiry * 1000);
    }
  }

  find() {
    if (isNaN(this.folder.id)) { return; }
    this.folderService
      .find(this.folder.id)
      .subscribe((response) => (this.folder = response.data));
  }

  /**
   * Removes the expiry date of the folder.
   *
   * @return {void}
   */
  onRemoveExpiryDate(): void {
    // Set the folder expiry to null.
    this.folder.expiry = null;
    // Delete the date object.
    delete this.date;
  }

  onRemoveFiles() {
    const dialogRef = this.dialog.open(FilesRemoveComponent, {
      data: this.folder.files,
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(() => this.find());
  }

  async onSubmit() {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;

    // try to upload the files and handle the response gracefully.
    try {
      this.folder.files = await this.fileService.createManyFromForm(this.newFiles);
    } catch (error) {
      // Reset the in progress status.
      this.inProgress = false;
      return;
    }

    // Check if an expiry date was selected.
    if ( this.date ) {
      // Create a new moment from the selected date and timezone.
      const date_to_save = moment.tz(moment(this.date).format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedTimezone);
      // Convert the expiry date into a unix timestamp.
      this.folder.expiry = date_to_save.unix();
    }

    this.folder.id ? await this.update() : await this.create();
  }

  async create() {
    this.folderService
      .create({ site_specific: true, ...this.folder })
      .subscribe({
        next: () => {
          this.utils.showToast('Created a Folder');
          this.dialogRef.close();
          this.inProgress = false;
        },
        error: () => this.inProgress = false,
        complete: () => this.inProgress = false
      });
  }

  async update() {
    this.folderService
      .update({ site_specific: true, ...this.folder })
      .subscribe({
        next: () => {
          this.utils.showToast('Updated a Folder');
          this.newFiles = null;
          this.inProgress = false;
          this.find();
        },
        error: () => this.inProgress = false,
        complete: () => this.inProgress = false
      });
  }

  async delete() {
    this.utils.showModal(
      'Remove Item',
      `Are you sure you want to remove "${this.folder.name}"?`,
      () => {
        this.folderService.delete([this.folder.id]).subscribe(() => {
          this.utils.showToast('Deleted Folder.');
          this.dialogRef.close();
        });
      }
    );
  }

  onSelectSites() {
    this.folder.sites = this.folder.sites || [];
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.folder.sites.map((site) => site.id),
        multiple: true,
        returnAllSelected: true
      },
      {},
      (results: { selected: number[]; isAllSelected: boolean }) => {
        if (typeof results !== 'undefined') {
          this.folder.sites = results.selected.map((result: number) => {
            return { id: result } as SiteModel;
          });
          this.isAllSelected = results.isAllSelected;
        }
      }
    );
  }

  getOutputTimezone($event) {
    this.selectedTimezone = $event;
  }
}
