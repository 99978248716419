import {Component, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SiteModel } from 'src/app/models/site.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import moment from 'moment';
import {SitesSelectorComponent} from "../../shared/sites-selector/sites-selector.component";
import {TagsSelectorComponent} from "../../shared/tags-selector/tags-selector.component";

declare var google;

@Component({
  selector: 'app-sites-details-edit',
  templateUrl: './sites-details-edit.component.html',
  styleUrls: ['./sites-details-edit.component.scss']
})
export class SitesDetailsEditComponent implements OnInit {
  /**
   * Is the form being submitted?
   */
  inProgress = false;

  // the site model placeholder
  site: SiteModel = new SiteModel();

  site_risk_assessments: string[] = [
    'very low',
    'low',
    'moderate',
    'high',
    'critical'
  ];

  estimatedProjectStartDate: moment.Moment;
  estimatedProjectEndDate: moment.Moment;

  /**
   * Site statuses and site types are pulled from the utils service file.
   */

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    // Get the site id from the route params.
    const parent_id: number = Number(this.route.parent.snapshot.params['parent_id']);
    const child_id: number = Number(this.route.parent.snapshot.params['child_id']);

    // Check if we are updating a site or creating a new one.
    if ( child_id || (typeof this.route.parent.snapshot.params['child_id'] == 'undefined' && parent_id) ) {
      // Store the site id.
      this.site.id = child_id ? child_id : parent_id;
      // Get the data from the API.
      this.api.laravelApiRequest(
        'get',
        'sites/' + this.site.id,
        {},
        {},
        (response) => {
          // apply the data
          this.site.apply(response.data);

          // Set the estimated project start date
          if (this.site.estimated_project_start_date) {
            this.estimatedProjectStartDate = moment.unix(this.site.estimated_project_start_date);
          }

          // Set the estimated project end date
          if (this.site.estimated_project_end_date) {
            this.estimatedProjectEndDate = moment.unix(this.site.estimated_project_end_date);
          }

          // Extract Tag ids.
          this.site.tag_ids = this.site.tags ? this.site.tags.map((tag) => tag.id) : [];
        },
        (error) => {
          this.utils.showModal('Error', error.message);
        }
      );
    } else {
      // Get and store the parent site id if it is present.
      if ( parent_id ) {
        // Store the site id.
        this.site.parent_site_id = parent_id;
      }

      // Load the Google Maps script and configure the map once it is loaded.
      this.utils.loadExternalScript('google-maps-script', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA91kej0UNf8eHR_O_iTY7oFZVFTSVxk2I&libraries=drawing,places,geometry', () => {
        // Load the default map configuration after 300 milliseconds.
        setTimeout(() => {
          // set the default geofence bounds and coordinates
          const initBounds = new google.maps.LatLngBounds();
          initBounds.extend(new google.maps.LatLng(-36.848552, 174.762105));

          // add address lookup
          const autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('location')
          );
          autocomplete.setBounds(initBounds);
          autocomplete.addListener('place_changed', () => {
            // get the place
            const place = autocomplete.getPlace();
            // store the location
            this.site.location = place.formatted_address;
          });
        }, 300);
      });
    }
  }

  // get timezone output from timezone select component
  getOutputTimezone($event) {
    this.site.timezone = $event;
  }

  /**
   * Create or update the site.
   * @param form
   */
  onSubmit(form: NgForm) {
    // make sure the form data is valid
    if (form.valid) {
      if (this.inProgress) {
        return;
      }

      this.inProgress = true;

      // Convert the estimated project start and end dates to a unix timestamps.
      if (this.estimatedProjectStartDate) {
        this.site.estimated_project_start_date = this.estimatedProjectStartDate.unix();
      } else {
        this.site.estimated_project_start_date = null;
      }

      if (this.estimatedProjectEndDate) {
        this.site.estimated_project_end_date = this.estimatedProjectEndDate.unix();
      } else {
        this.site.estimated_project_end_date = null;
      }

      // Check if the site should be created or updated.
      if (this.site.id) {
        this.api.laravelApiRequest(
          'put',
          'sites/' + this.site.id,
          this.site,
          {},
          (response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site').toLowerCase() + ' was updated.');
            this.inProgress = false;
          },
          () => this.inProgress = false
        );
      } else {
        // Create a new site.
        this.api.laravelApiRequest(
          'post',
          'sites',
          this.site,
          {},
          (response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site').toLowerCase() + ' was added.');
            this.inProgress = false;

            // Refresh the component to invoke change detection for the new route.
            this.router
              .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
              .then(() => {
                this.router.navigate([
                  '/sites',
                  response.data.id,
                  'edit',
                  'details'
                ]);
              });
          },
          () => this.inProgress = false
        );
      }
    } else {
      this.utils.showModal('Form Error', 'Please make sure you enter all fields with valid information.');
    }
  }

  /**
   * Remove a single site.
   */
  onRemove() {
    this.utils.showModal(
      'Remove ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site'),
      'Are you sure you want to remove this ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site').toLowerCase() + '?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'sites/' + this.site.id,
          {},
          {},
          (response) => {
            this.utils.showToast('The ' + this.utils.getLangTerm(this.site.parent_site_id ? 'child-sites.singular' : 'sites.singular', this.site.parent_site_id ? 'Subsite' : 'Site').toLowerCase() + ' was removed.');
            this.router.navigate(['/sites']);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  /**
   * Show the site start and end dates if the status of the site is any of:
   * - Pre-Construction
   * - Under Construction
   * - Hand Over Complete
   *
   * @param site_status The status of the site.
   */
  showProjectStartEndDates(site_status: string) {
    return [
      'Pre-Construction',
      'Under Construction',
      'Hand Over Complete'
    ].indexOf(site_status) > -1;
  }

  /**
   * Added a new site selector to link sites with parent sites.
   */
  onSelectParentSite() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: [this.site.parent_site_id],
        multiple: false,
        allow_no_selection: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if ( typeof results !== 'undefined' ) {
          this.site.parent_site_id = results;
        }
      }
    );
  }

  selectTags() {
    this.utils.showComponentDialog(
      TagsSelectorComponent,
      {
        selected: this.site.tag_ids,
        multiple: true},
      {
        width: '1024px'
      },(results: number[]) => {
        if (!results) { return; }
        this.site.tag_ids = results;
      }
    );
  }
}
