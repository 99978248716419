import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { SsspDocModel } from 'src/app/models/sssp-doc-model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import moment from 'moment';
import { SsspModel } from 'src/app/models/sssp-model';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';

@Component({
  selector: 'app-sssp-doc',
  templateUrl: './sssp-doc.component.html',
  styleUrls: ['./sssp-doc.component.scss']
})
export class SsspDocComponent implements OnInit {

  @Input('sssp') sssp: SsspModel = new SsspModel();

  @Input('sssp_hash') sssp_hash: string;

  @Input('sssp_doc') sssp_doc: SsspDocModel = new SsspDocModel();

  @Output('onDeleted') onDeleted: EventEmitter<boolean> = new EventEmitter();

  selectedFiles: File[] = [];

  expiresAtDateTime: moment.Moment;

  selectedTimezone: string;

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.sssp_doc = new SsspDocModel().apply(this.sssp_doc);

    this.onRefreshSsspDoc();
    this.selectedTimezone = this.sssp.site.timezone;
  }

  getOutputTimezone($event) {
    this.selectedTimezone = $event;
  }

  onRefreshSsspDoc() {
    this.api.makeRequest('get', `v1/sssp/${this.sssp_hash}/docs/${this.sssp_doc.id}`, this.sssp_doc)
    .then((response) => {
      this.sssp_doc.apply(response);

      // Set the expiry date for the form
      if ( this.sssp_doc.expires_at ) {
        this.expiresAtDateTime = moment.unix(this.sssp_doc.expires_at);
      }

    })
    .catch((errorResponse) => {
      this.utils.showModal('Error', errorResponse.error.message);
    });
  }

  onSave(form: NgForm): Promise<any> {
    // Validate the form inputs.
    if ( !form.valid ) {
      this.utils.showFormValidationError();
      return;
    }

    // Convert the form expiry date to a unix timestamp.
    if ( this.expiresAtDateTime ) {
      this.sssp_doc.expires_at = this.expiresAtDateTime.tz(this.selectedTimezone).unix();
    } else {
      this.sssp_doc.expires_at = null;
    }

    let request: Promise<any>;

    if ( this.sssp_doc.id ) {
      request = this.api.makeRequest('put', `v1/sssp/${this.sssp_hash}/docs/${this.sssp_doc.id}`, this.sssp_doc)
      .then((response) => {
        this.sssp_doc.apply(response);
      });
    } else {
      request = this.api.makeRequest('post', `v1/sssp/${this.sssp_hash}/docs`, this.sssp_doc)
      .then((response) => {
        this.sssp_doc.apply(response);
      });
    }

    // Handle API errors
    request.catch((errorResponse) => {
      this.utils.showModal('Error', errorResponse.error.message);
    });

    // Upload files
    return request.then(() => {
      // Upload any files if the message id is present.
      if ( this.sssp_doc.id && this.selectedFiles.length > 0 ) {
        return this.api.makeUploadRequest(`v2/file-manager/sssp_doc/${this.sssp_doc.id}`, this.selectedFiles)
        .then(() => {
          this.onRefreshSsspDoc();
        })
        .finally(() => {
          this.selectedFiles.length = 0;
        });
      }
    })
  }

  /**
   * Store for for uploading when the form is save.
   * @param files Files from the file selector.
   */
  onSelectFiles(files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.length = 0;
      this.selectedFiles.push(...files);
    }
  }

  /**
   * Save the form and refresh the item to get newly uploaded files.
   * @param form The form for the Safety Plan doc.
   */
  onSaveAndRefresh(form: NgForm) {
    if ( this.sssp_doc.id && form ) {
      this.onSave(form)
      .finally(() => {
        this.onRefreshSsspDoc();
      });
    }
  }

  /**
   * Compose a new message and save the doc to upload the files.
   * @param contractor_id The contractor id.
   * @param form The form reference for validation.
   */
  onComposeNewMessage(contractor_id: number, form: NgForm) {
    if ( !contractor_id ) {
      this.utils.showToast(`The provided ${this.utils.getLangTerm('contractors.singular', 'Contractor')} id is not valid.`);
      return;
    }

    // Save the doc before opening the message composer.
    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectAccountIds: [contractor_id],
      preSelectFileBlobs: this.selectedFiles,
      preSelectFiles: this.sssp_doc.files,
      prePopulateMessage: this.sssp_doc.doc_comments
    })
    .then(() => {
      this.utils.showToast('You can view the message stats in the messages section.');
      this.onSave(form);
    });
  }

  onDeleteSSSPDoc(evt: any) {
    if ( this.sssp_doc.id ) {
      this.utils.showQuickActions(evt, 'Are you sure you want to archive (remove) this document?', [
        {
          text: 'Yes',
          handler: () => {
            this.api.makeRequest('delete', `v1/sssp/${this.sssp_hash}/docs/${this.sssp_doc.id}`)
            .then(() => {
              this.onDeleted.emit(true);
            });
          }
        },
        {
          text: 'No',
          handler: () => { }
        }
      ]);
    }
  }

}
