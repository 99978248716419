import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitCsv'
})
export class SplitCsvPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return value.split(',').join('');
  }
}
