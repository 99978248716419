import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { AppService } from 'src/app/app.service';
import { ContractorsAddComponent } from 'src/app/contractors/contractors-add/contractors-add.component';
import { EmployeesAddComponent } from 'src/app/employees/employees-add/employees-add.component';
import { TimeOnsiteRecordModel } from 'src/app/models/reports/time-onsite-record.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { NetworkedAccountsSelectorComponent } from 'src/app/shared/networked-accounts-selector/networked-accounts-selector.component';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-manage-time-onsite-record',
  templateUrl: './manage-time-onsite-record.component.html',
  styleUrls: ['./manage-time-onsite-record.component.scss']
})
export class ManageTimeOnsiteRecordComponent implements OnInit {

  @ViewChild('recordDateTimePicker') recordDateTimePicker: NgxMatDatetimePicker<any>;

  recordDateTime: moment.Moment = moment();

  timeOnsiteRecord: TimeOnsiteRecordModel = new TimeOnsiteRecordModel();

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
  }

  onSave(form: NgForm) {

  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: [this.timeOnsiteRecord.site_id],
        multiple: false
      },
      {
        width: '800px'
      },
      ( selectedId: number ) => {
        if ( typeof selectedId != 'undefined' ) {
          this.timeOnsiteRecord.site_id = selectedId;
        }
      }
    );
  }

  onSelectAccounts() {
    this.utils.showComponentDialog(
      NetworkedAccountsSelectorComponent,
      {
        selected: this.timeOnsiteRecord.user_account_id,
        multiple: false
      },
      {
        width: '800px'
      },
      (selectedId: number) => {
        if (typeof selectedId != 'undefined') {
          this.timeOnsiteRecord.user_account_id = selectedId;
        }
      }
    );
  }

  onAddAccount() {
    this.utils.showComponentDialog(ContractorsAddComponent, {}, {
      width: '800px'
    }, (response) => {
      if ( response ) {
        this.timeOnsiteRecord.user_account_id = response.id;
      }
    });
  }

  onSelectUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.timeOnsiteRecord.user_id, // Todo
      multiple: false,
      selectedAccountId: this.timeOnsiteRecord.user_account_id,
      visitors_from_all_sites: true
    }, {
      width: '800px'
    },
    (selectedId: number) => {
      if (typeof selectedId != 'undefined') {
        this.timeOnsiteRecord.user_id = selectedId;
      }
    });
  }

  onAddUser() {
    this.utils.showComponentDialog(EmployeesAddComponent, {}, {
      width: '800px'
    })
    .then((response) => {
      if ( response ) {
        this.timeOnsiteRecord.user_id = response.id;
      }
    });
  }

}
