import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { SafetyObservation } from 'src/app/models/safety-observation.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { NetworkedAccountsSelectorComponent } from 'src/app/shared/networked-accounts-selector/networked-accounts-selector.component';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';
import { SafetyObservationsViewComponent } from '../safety-observations-view/safety-observations-view.component';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import {NgxMatDatetimePicker} from "@angular-material-components/datetime-picker";
import moment from "moment/moment";

@Component({
  selector: 'app-safety-observations-edit',
  templateUrl: './safety-observations-edit.component.html',
  styleUrls: ['./safety-observations-edit.component.scss']
})
export class SafetyObservationsEditComponent implements OnInit {

  safety_observation: SafetyObservation = new SafetyObservation();

  selectedFiles: File[] = [];
  faHardHat = faHardHat;

  // Used to extract the date and time from a date/time picker.
  @ViewChild('reportedDateTimePickerRef') reportedDateTimePickerRef: NgxMatDatetimePicker<any>;
  reportedDateTime: moment.Moment;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService, // Is used in view.
    private dialogRef: MatDialogRef<SafetyObservationsEditComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit() {
    // Get the id of the safety observation.
    const safety_observation_id: number = Number(this.dialogData['safety_observation_id']);

    if ( safety_observation_id ) {
      // Get the safety observation
      this.getSafetyObservation(safety_observation_id);
    } else {
      // A new safety observation is being reported.
      const site_id: number = Number(this.dialogData['site_id']);
      if ( site_id ) {
        this.safety_observation.site_id = site_id;
      }
    }
  }

  /**
   * Get the safety observation data from the API.
   * @param safety_observation_id The safety observation id.
   */
  private getSafetyObservation(safety_observation_id: number) {
    this.api.makeRequest('get', `v2/safety-observations/${safety_observation_id}`)
    .then((response) => {
      // Store the safety observation information.
      this.safety_observation.apply(response);
      // Convert the unix "reported_at" timestamp to a moment date/time object.
      this.reportedDateTime = moment.unix(this.safety_observation.reported_date);
    })
    .catch((errorResponse) => {
      this.utils.handleAPIErrors(errorResponse);
    });
  }

  /**
   * Save or update and existing safety observation based on the enetered form data.
   * @param form The form data.
   * @param continueWhenDone
   * @returns
   */
  async onSaveSafetyObservation(form: NgForm, continueWhenDone: boolean = false) {
    if ( !form.valid ) {
      this.utils.showFormValidationError();
      return;
    }

    const safetyObservationCopy = {...this.safety_observation};

    // Get the relevant timezone to use.
    let timezone: string = this.app.account.timezone;
    if ( typeof this.safety_observation.site.timezone != 'undefined' ) {
      // Use the associated site timezone instead.
      timezone = this.safety_observation.site.timezone;
    }

    // Convert the selected date and time to a unix timestamp.
    const reportedDateTime: moment.Moment = moment.tz(this.reportedDateTime.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', timezone);
    safetyObservationCopy.reported_date = reportedDateTime.unix();


    // check if the Safety Observation should be created or updated
    if (this.safety_observation.id) {
      await this.update(safetyObservationCopy as SafetyObservation, continueWhenDone);
    } else {
      await this.create(safetyObservationCopy as SafetyObservation, continueWhenDone);
    }

  }

  private create(safety_observation: SafetyObservation, continueWhenDone: boolean = false): Promise<any> {
    return this.api.makeRequest('post', `v2/safety-observations`, safety_observation)
      .then((response) => {
        this.safety_observation.apply(response);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      })
      .finally(() => {
        if ( this.selectedFiles.length > 0 ) {
          this.api.makeUploadRequest(`v2/file-manager/safety_observation/${safety_observation.id}`, this.selectedFiles)
            .then((response) => {
              this.utils.showToast('Your files successfully uploaded.');
            })
            .finally(() => {
              this.selectedFiles.length = 0;
            });
        }

        // If this is a new safety observation, open it in viewing mode.
        if ( continueWhenDone ) {
          this.onViewSafetyObservation(this.safety_observation.id);
        }

        // Close the editing dialog.
        this.dialogRef.close();
      });
  }

  private update(safety_observation: SafetyObservation, continueWhenDone: boolean = false): Promise<any> {
    return this.api.makeRequest('put', `v2/safety-observations/${safety_observation.id}`, safety_observation)
      .then((response) => {
        this.getSafetyObservation(this.safety_observation.id)
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      })
      .finally(() => {
        if ( this.selectedFiles.length > 0 ) {
          this.api.makeUploadRequest(`v2/file-manager/safety_observation/${safety_observation.id}`, this.selectedFiles)
            .then((response) => {
              this.utils.showToast('Your files successfully uploaded.');
            })
            .finally(() => {
              this.selectedFiles.length = 0;
            });
        }

        // If this is a new safety observation, open it in viewing mode.
        if ( continueWhenDone ) {
          this.onViewSafetyObservation(this.safety_observation.id);
        }

        // Close the editing dialog.
        this.dialogRef.close();
      });
  }

  /**
   * View the safety observation.
   * @param safety_observation_id The id of the safety observation to view.
   */
   onViewSafetyObservation(safety_observation_id: number) {
    this.utils.showComponentDialog(SafetyObservationsViewComponent, {
      safety_observation_id: safety_observation_id
    });
  }

  // [displayWith]="formatRatingLabel" on mat-slider.
  formatRatingLabel(rating: number) {
    switch( rating ) {
      case 1:
        return 'Very Bad';
        break;
      case 2:
        return 'Bad';
        break;
      case 3:
        return 'Neutral';
        break;
      case 4:
        return 'Good';
        break;
      case 5:
        return 'Very Good';
        break;
      default:
        return '';
    }
  }

  /**
   * Select the site the safety observation should be associated with.
   */
  onSelectSite() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: [this.safety_observation.site_id],
        multiple: false
      },
      {
        width: '1024px'
      },
      (selected_site_id: number) => {
        if ( typeof selected_site_id != 'undefined' ) {
          this.safety_observation.site_id = selected_site_id;
        }
      }
    );
  }

  /**
   * Select contractors to associate with this safety observation.
   */
  onSelectContractors() {
    this.utils.showComponentDialog(
      NetworkedAccountsSelectorComponent,
      {
        selected: this.safety_observation.contractor_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: any) => {
        if (typeof results !== 'undefined') {
          // Set the newly selected ids.
          this.safety_observation.contractor_ids = results;
        }
      }
    );
  }

  /**
   * Select users to associate with this safety observation.
   */
  onSelectUsers() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        selected: this.safety_observation.user_ids,
        multiple: true,
        visitors_from_all_sites: true
      },
      {
        width: '1024px'
      },
      (results: any) => {
        if (typeof results !== 'undefined') {
          // Set the newly selected ids.
          this.safety_observation.user_ids = results;
        }
      }
    );
  }

  /**
   * If it is a new safety observation, add the files to the selected files array.
   * Existing safety observation files will be handled by the file manager.
   * @param files List of files.
   */
  onSelectFiles(files?: any[]) {
    if ( files && files.length > 0 ) {
      this.selectedFiles.push(...files);
    }
  }

  /**
   * Select reporter to associate with this safety observation.
   */
  onSelectReporter() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.safety_observation.reported_by],
        selectedAccountId: this.app.account.id,
        visitors_from_all_sites: true
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.safety_observation.reported_by = userId;
      }
    );
  }

}
