import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { HazardousSubstanceModel } from '../../models/hazardous-substance.model';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';
import { FileService } from 'src/app/shared/file.service';
import { PpeSelectorComponent } from '../../shared/ppe-selector/ppe-selector.component';
import moment from 'moment';

@Component({
  selector: 'app-hazardous-substances-edit',
  templateUrl: './hazardous-substances-edit.component.html',
  styleUrls: ['./hazardous-substances-edit.component.css']
})
export class HazardousSubstancesEditComponent implements OnInit {

  hazardous_substance: HazardousSubstanceModel = new HazardousSubstanceModel();

  search = '';

  newFiles: FileList;

  inProgress = false;

  // The current date for min expiry date selection.
  currentDate: Date = moment().toDate();

  // The selected expiry date object.
  selectedExpiryDate: any = null;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public app: AppService,
    private api: ApiService,
    public utils: UtilsService,
    public renderer: Renderer2,
    private dialog: MatDialog,
    private fileService: FileService
  ) {}

  ngOnInit() {
    // get the site id
    this.hazardous_substance.id = Number(this.route.snapshot.params['hazardous_substance_id']);

    // Get the hazardous substance info.
    this.find();
  }

  onRemoveFiles() {
    const dialogRef = this.dialog.open(FilesRemoveComponent, {
      data: this.hazardous_substance.files,
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(() => this.find());
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.hazardous_substance.site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.hazardous_substance.site_ids = results;
        }

        // TBD: refresh a list of selected sites?
      }
    );
  }

  async onSubmit(form: NgForm) {
    // Check if the submitted form is valid.
    if ( !form.valid ) {
      alert('Please make sure you enter all fields with valid information.');
      return;
    }

    // Check if another API request is in progress.
    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    // try to upload the files and handle the response gracefully.
    try {
      // Prepare files that should be uploaded with the hazardous substance.
      this.hazardous_substance.files = await this.fileService.createManyFromForm(this.newFiles);
    } catch (error) {
      // Reset the in progress status.
      this.inProgress = false;
      return;
    }

    // Prepare the SDS expiry date.
    this.hazardous_substance.sds_expires_at = this.selectedExpiryDate ? this.selectedExpiryDate.unix() : null;

    // Check if we need to create a new or update an existing hazardous substance.
    if ( this.hazardous_substance.id ) {
      this.update();
    } else {
      this.create();
    }
  }

  getRiskAssessmentColorChange(likelihood: number, severity: number) {
    const riskAssessment = this.utils.getRiskAssessmentText(
      likelihood,
      severity
    );

    if (['Very Low', 'Low'].find((value) => value === riskAssessment)) {
      return 'success';
    }

    if (['Moderate'].find((value) => value === riskAssessment)) {
      return 'warning';
    }

    if (['High', 'Critical'].find((value) => value === riskAssessment)) {
      return 'danger';
    }

    return 'danger';
  }

  onRemove() {
    this.utils.showModal(
      'Remove Hazardous Substance',
      'Are you sure you want to remove this hazardous substance?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'hazardous_substances/' + this.hazardous_substance.id,
          {},
          {},
          () => {
            this.utils.showToast('The hazardous substance was removed');
            this.router.navigate(['hazardous_substances']);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  private find() {
    // Check if we are viewing an existing hazardous substance.
    if ( this.hazardous_substance.id ) {
      this.api.laravelApiRequest('get', 'hazardous_substances/' + this.hazardous_substance.id, {}, {}, (response) => {
        // Store the hazardous substance.
        this.hazardous_substance.apply(response.data);

        // Extract the PPE ids.
        this.hazardous_substance.ppe_ids = this.hazardous_substance.ppe.map((ppe) => ppe.id);

        // Set the current expiry date if it was previously set.
        if ( this.hazardous_substance.sds_expires_at ) {
          this.selectedExpiryDate = moment.unix(this.hazardous_substance.sds_expires_at);
        }
      },
      (error) => {
        this.utils.showModal('Error', error.message);
      });
    }
  }

  private update() {
    this.api.laravelApiRequest(
      'put',
      'hazardous_substances/' + this.hazardous_substance.id,
      this.hazardous_substance,
      {},
      () => {
        this.utils.showToast('The hazardous substance was updated.');
        this.inProgress = false;
        this.newFiles = null;
        this.find();
      },
      (error) => {
        this.utils.showModal('Error', error.message);
        this.inProgress = false;
      }
    );
  }

  private create() {
    this.api.laravelApiRequest(
      'post',
      'hazardous_substances',
      this.hazardous_substance,
      {},
      (response) => {
        this.utils.showToast('The hazardous substance was created.');
        this.hazardous_substance.id = response.data.id;
        this.router.navigate(['/hazardous_substances']);
        this.inProgress = false;
      },
      (error) => {
        this.utils.showModal('Error', error.message);
        this.inProgress = false;
      }
    );
  }

  /**
   * Change the unit type based on the Hazardous Substance type.
   * @param hazardous_substance_type
   */
  onChangeUnitType(hazardous_substance_type: string) {
    this.hazardous_substance.hazardous_substance_unit_type = this.utils.getHazardousSubstanceUnitType(hazardous_substance_type);
  }

  /**
   * Open the PPE selector to select new PPE records to associate the hazardous substance with.
   */
  onManagePPE() {
    this.utils.showComponentDialog(PpeSelectorComponent, {
      multiple: true,
      selected: this.hazardous_substance.ppe_ids
    })
      .then((response) => {
        if ( typeof response != 'undefined' ) {
          this.hazardous_substance.ppe_ids = response;
        }
      })
  }
}
