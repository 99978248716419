import { Pipe, PipeTransform } from '@angular/core';
import { ContractorModel } from '../models/contractor.model';

@Pipe({
  name: 'inductionIcon'
})
export class InductionIconPipe implements PipeTransform {
  transform(contractor: ContractorModel, args?: any): any {
    if (
      // No inductions exist.
      contractor.inductions_count === 0 ||
      // Every induction is complete.
      contractor.inductions_completed === contractor.inductions_count ||
      // Your inductions are in progress.
      contractor.inductions_count > 0 && contractor.inductions_completed > 0
    ) {
      return 'check';
    }
    // You haven't even started your inductions.
    return 'clear';
  }
}
